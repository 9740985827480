import Fade from 'react-reveal/Fade'
import { Link } from 'react-router-dom'
import style from '../style/Case.module.css'

import case01 from '@/assets/images/home/case01.png'
import case02 from '@/assets/images/home/case02.png'
import case03 from '@/assets/images/home/case03.png'
import case04 from '@/assets/images/home/case04.png'
import case05 from '@/assets/images/home/case05.png'
import case06 from '@/assets/images/home/case06.png'

function Case() {
  return (
    <div className={`flex_column_center item_top min_width`}>
      <Fade top>
        <span className='title'>多种智能解决方案</span>
        <span className={`space_bottom ${style.subtitle}`}>精彩一触即发</span>
      </Fade>

      <Fade bottom>
        <div className={`flex_column_center_center ${style.box}`}>
          <img src={case01} alt='智慧家居解决方案' className={style.img} />
          <div className={style.shadow}></div>

          <div className={`flex_column_center_center ${style.con}`}>
            <span className={style.name_en}>Smart Home Solutions</span>
            <span className={style.name_cn}>智慧家居解决方案</span>
            <Link to="/project/family">
              <div className={style.btn}>查看方案</div>
            </Link>
          </div>
        </div>
      </Fade>

      <div className={`flex_row_center_between`}>
        <Fade left>
          <div className={`flex_column_center_center ${style.box}  ${style.box_item}`}>
            <img src={case02} alt='智慧影音解决方案' className={style.img} />
            <div className={style.shadow}></div>

            <div className={`flex_column_center_center ${style.con}`}>
              <span className={style.name_en}>Smart Video Solutions</span>
              <span className={style.name_cn}>智慧影音解决方案</span>
              <Link to="/project/video">
                <div className={style.btn}>查看方案</div>
              </Link>
            </div>
          </div>
        </Fade>
        <Fade top>
          <div className={`flex_column_center_center ${style.box}  ${style.box_item}`}>
            <img src={case03} alt='智慧家居解决方案' className={style.img} />
            <div className={style.shadow}></div>

            <div className={`flex_column_center_center ${style.con}`}>
              <span className={style.name_en}>Smart Office Solutions</span>
              <span className={style.name_cn}>智慧办公解决方案</span>
              <Link to="/project/office">
                <div className={style.btn}>查看方案</div>
              </Link>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className={`flex_column_center_center ${style.box}  ${style.box_item}`}>
            <img src={case04} alt='智慧园区解决方案' className={style.img} />
            <div className={style.shadow}></div>

            <div className={`flex_column_center_center ${style.con}`}>
              <span className={style.name_en}>Smart Park Solutions</span>
              <span className={style.name_cn}>智慧园区解决方案</span>
              <Link to="/project/park">
                <div className={style.btn}>查看方案</div>
              </Link>
            </div>
          </div>
        </Fade>
      </div>

      <div className={`flex_row_center_between`}>
        <Fade left>
          <div className={`flex_column_center_center ${style.box}`}>
            <img src={case05} alt='智慧酒店解决方案' className={style.img} />
            <div className={style.shadow}></div>

            <div className={`flex_column_center_center ${style.con}`}>
              <span className={style.name_en}>Smart Hotel Solutions</span>
              <span className={style.name_cn}>智慧酒店解决方案</span>
              <Link to="/project/hotel">
                <div className={style.btn}>查看方案</div>
              </Link>
            </div>
          </div>
        </Fade>
        <Fade right>
          <div className={`flex_column_center_center ${style.box}`}>
            <img src={case06} alt='智慧社区方案' className={style.img} />
            <div className={style.shadow}></div>

            <div className={`flex_column_center_center ${style.con}`}>
              <span className={style.name_en}>Smart Community Solutions</span>
              <span className={style.name_cn}>智慧社区方案</span>
              <Link to="/project/community">
                <div className={style.btn}>查看方案</div>
              </Link>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Case