import { useState, useEffect, useCallback } from 'react'
import { Menu } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import Fade from 'react-reveal/Fade'
import Banner from '@/components/Banner'
import List from './components/List'
import { changeProductCurrent } from '@/store/actions/productAction'

import style from './style/index.module.css'
import { find_products, find_menus } from '@/api/product'

import type { Product as ProductProps } from './components/List'

interface MenuProps {
  id:number
  name:string
}

const { SubMenu } = Menu

function Product() {

  const dispatch = useDispatch()
  const state = useSelector((state:any) => state)
  const [menus, setMenus] = useState([] as MenuProps[])
  const [product, setProduct] = useState({} as ProductProps) 
  const [current, setCurrent] = useState(0)
   
  const banner = 'https://static.iot.a-bene.com/abene-static/www/product/tu1.png'
  // 获取产品信息
  const handleGetProduct = useCallback(() => {
    const param = {
      pageNumber: 1,
      pageSize: 10,
      id: current
    }
    find_products(param).then((response:any) => {
      if (response?.code === 200) {
        const { list = [], total = 0 } = response
        const datas = { data: list, total: parseInt(total) }
        setProduct(datas)
      }
    })
  }, [current])
  
  // 获取菜单（分类）
  const getMenuList = () => {
    find_menus({}).then((response:any) => {
      if (response?.code === 200) {
        const { list = [] } = response
        setMenus(list)
        setCurrent(state.product.current || list?.[0]?.id)
      }
    })
  }

  useEffect(() => {
    getMenuList()
    if(state.product.current && current !== state.product.current){
      setCurrent(state.product.current)
    }
    handleGetProduct()
  }, [current, state.product.current])

  
  // 渲染菜单
  const renderPrevMenus = () => {
    const newMenus = JSON.parse(JSON.stringify(menus))
    const prevMenus = newMenus.slice(0, 6)
    return (
      prevMenus.length > 0 && prevMenus.map((item:MenuProps) => {
        return <Menu.Item key={item.id} className={`${style.menu_item} ${item.id === current ? style.menu_action : ''}`}>{ item.name }</Menu.Item>
      })
    )
  }

  // 渲染其他菜单
  const renderNextMenus = () => {
    const newMenus = JSON.parse(JSON.stringify(menus))
    const otherMenus = newMenus.slice(6, newMenus.length)
    if (otherMenus.length > 0) {
      return (
        <SubMenu key="other_id" title="其他" className={`${style.menu_item} ${style.other_menu}`}>
          {
            otherMenus.length > 0 && otherMenus.map((item:MenuProps) => {
              return <Menu.Item key={item.id}>{ item.name }</Menu.Item>
            })
          }
        </SubMenu>
      )
    }
  }

  // 点击菜单
  const handleClickMenu = (e:any) => {
    const key = Number(e.key)
    setCurrent(key)
    dispatch(changeProductCurrent(key))
  }

  return (
    <>
      <Fade top>
        {Banner(banner)}
      </Fade>

      <Fade bottom>
        <Menu onClick={handleClickMenu} selectedKeys={[current + '']} mode="horizontal" className={`min_width ${style.nav_menu}`}>
          { renderPrevMenus() }
          { renderNextMenus() }
        </Menu>
      </Fade>

      <Fade bottom>
        { List(product, current + '') }
      </Fade>
    </>
  )
}

export default Product