import Fade from 'react-reveal/Fade'
import Banner from '@/components/Banner'
import Method from './components/Method'

function Contact() {

  const banner = 'https://static.a-bene.com/abene-static/7d13bba366d960bf1d5e5eb1bbd4cd4c.png'

  return (
    <>
      <Fade top>
        { Banner(banner) }
      </Fade>
      <Method />
    </>
  )
}

export default Contact