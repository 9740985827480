
import Fade from 'react-reveal/Fade'
import style from '../style/SystemVideo.module.css'
import video01 from '@/assets/images/family/video01.png'

function SystemVideo() {
  return (
    <>
      <div id='video' className={`flex_column_center min_width max_width ${style.bg}`}>
        <Fade top>
          <span className={`item_top space_bottom ${style.title}`}>以全新姿态为您创造智能影音新体验</span>
          <span className={`space_bottom ${style.desc}`}>支持多种模式，满足不同的应用场合，各个模式可以快速切换，操作方便快捷</span>
          <span className={`space_bottom ${style.desc}`}>一键开启观影模式，所有设备依次启动</span>
        </Fade>
        <Fade bottom>
          <div className={`content content_space`}>
            <img src={video01} alt='影音介绍' className={`content_max ${style.img_bg}`} />
          </div>
        </Fade>
      </div >
    </>
  )
}

export default SystemVideo