import { Row, Col } from 'antd'
import Fade from 'react-reveal/Fade'
import style from '../style/Product.module.css'

export interface Products {
  id:string
  MingCHen:string
  TuPian:string
  BeiZHu:string
  [key:string]:any
}

function Product( list:Products[] ) {

  // 点击产品
  const handleClickProduct = (id:string) => {
    window.open(`/product/${id}`)
  }

  return (
    <div className={`flex_column_center min_width ${list.length > 0 ? '' : 'display_none' }`}> 
      <div className={`flex_column_center container ${style.content}`}>
        <Fade top>
          <span className={`title item_top space_bottom flex_row_center_center`}>产品推荐</span>
        </Fade>

        <Row gutter={20} className={`flex_row_center_between ${style.row}`}>
          <Col span={14}>
            <Fade left>
              <div onClick={ () => handleClickProduct(list?.[0]?.id) } className={`flex_row_center ${style.box} ${style.item_long} ${style.margin_right_45}`}>
                <img src={list?.[0]?.TuPian} alt={list?.[0]?.MingCHen} className={style.img} />
                <div className={`flex_column ${style.con}`}>
                  <span className={`space_bottom ${style.name}`}>{ list?.[0]?.MingCHen }</span>
                  <span className={style.desc}>{ list?.[0]?.BeiZHu }</span>
                </div>
              </div>
            </Fade>
          </Col>
          
          <Col span={10}>
            <Fade right>
              <div onClick={ () => handleClickProduct(list?.[1]?.id) } className={`flex_row_center ${style.item} ${style.box} ${list.length > 1 ? '' : 'display_none' }`}>
                <img src={list?.[1]?.TuPian} alt={list?.[1]?.MingCHen} className={style.img} />
                <div className={`flex_column ${style.con}`}>
                  <span className={`space_bottom ${style.name}`}>{ list?.[1]?.MingCHen }</span>
                  <span className={style.desc}>{ list?.[1]?.BeiZHu }</span>
                </div>
              </div>
            </Fade>
          </Col>
        </Row>

        <Row gutter={20} className={`flex_row_center_between  ${style.row} ${list.length > 2 ? '' : 'display_none' }`}>
          <Col span={10}>
            <Fade left>
              <div onClick={ () => handleClickProduct(list?.[2]?.id) } className={`flex_row_center ${style.item} ${style.box}`}>
                <img src={list?.[2]?.TuPian} alt={list?.[2]?.MingCHen} className={style.img} />
                <div className={`flex_column ${style.con}`}>
                  <span className={`space_bottom ${style.name}`}>{ list?.[2]?.MingCHen }</span>
                  <span className={style.desc}>{ list?.[2]?.BeiZHu }</span>
                </div>
              </div>
            </Fade>
          </Col>
          <Col span={14}>
            <Fade right>
              <div onClick={ () => handleClickProduct(list?.[3]?.id) } className={`flex_row_center ${style.item_long} ${style.box} ${list.length > 3 ? '' : 'display_none' }`}>
                <img src={list?.[3]?.TuPian} alt={list?.[3]?.MingCHen} className={style.img} />
                <div className={`flex_column ${style.con}`}>
                  <span className={`space_bottom ${style.name}`}>{ list?.[3]?.MingCHen }</span>
                  <span className={style.desc}>{ list?.[3]?.BeiZHu }</span>
                </div>
              </div>
            </Fade>
          </Col>
        </Row>

        <Fade bottom>
          <div onClick={ () => handleClickProduct(list?.[4]?.id) } className={`flex_row_center_between ${style.bottom_wrap} ${style.row} ${list.length > 4 ? '' : 'display_none' }`}>
            <div className={`flex_column_center ${style.item_short} ${style.short_box}`}>
              <img src={list?.[4]?.TuPian} alt={list?.[4]?.MingCHen} className={`${style.short_margin} ${style.img}`} />
              <div className={`flex_column ${style.box_con}`}>
                <span className={`${style.short_name} ${style.name}`}>{ list?.[4]?.MingCHen }</span>
                <span className={style.desc}>{ list?.[4]?.BeiZHu }</span>
              </div>
            </div>
            
            <div onClick={ () => handleClickProduct(list?.[5]?.id) } className={`flex_column_center ${style.item_short} ${style.short_box} ${style.margin_about_20} ${list.length > 5 ? '' : 'display_none' }`}>
              <img src={list?.[5]?.TuPian} alt={list?.[5]?.MingCHen} className={`${style.short_margin} ${style.img}`} />
              <div className={`flex_column ${style.box_con}`}>
                <span className={`${style.short_name} ${style.name}`}>{ list?.[5]?.MingCHen }</span>
                <span className={style.desc}>{ list?.[5]?.BeiZHu }</span>
              </div>
            </div>
            
            <div onClick={ () => handleClickProduct(list?.[6]?.id) } className={`flex_column_center ${style.item_short} ${style.short_box}  ${list.length > 6 ? '' : 'display_none' }`}>
              <img src={list?.[6]?.TuPian} alt={list?.[6]?.MingCHen} className={`${style.short_margin} ${style.img}`} />
              <div className={`flex_column ${style.box_con}`}>
                <span className={`${style.short_name} ${style.name}`}>{ list?.[6]?.MingCHen }</span>
                <span className={style.desc}>{ list?.[6]?.BeiZHu }</span>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Product