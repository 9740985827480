import { useState, useEffect} from 'react'

import Fade from 'react-reveal/Fade'
import { Carousel } from 'antd'
import { find_products_recommends } from '@/api/product'

import './style/antd.less'
import Case from './components/Case'
import Phone from './components/Phone'
import Product from './components/Product'

import type { Products } from './components/Product' 

function Home() {
  const [producs, setProducs] = useState([] as Products[])

  const bannerList = [
    'https://static.a-bene.com/abene-static/0a6f248cd3692f7a9ee84805575263ea.jpg',
    'https://static.a-bene.com/abene-static/0b9ea40e151e86fba1e1a121141c9b41.jpg',
    'https://static.a-bene.com/abene-static/7b6f7305f71800054a0c139995140ef0.jpg',
  ]

  // 获取产品
  const getProductList = () => {
    find_products_recommends({}).then((response:any)=> {
      if (response?.code === 200) {
        const data = response.list
        setProducs(data)
      }
    })
  }
  useEffect(() => {
    getProductList()
  }, [])  

  return (
    <>
      <Fade top>
        <Carousel autoplay className='min_width max_width home_page'>
          {
            bannerList.map((item:string, index:number) => {
              return <img key={index} src={item} alt={item} className={`min_width img`} />
            })
          }
        </Carousel>
      </Fade>
      <Case />
      { Product(producs) }
      <Phone />
    </>
  )
}

export default Home