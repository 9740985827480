import axios from '@/utils/request'

/**
 * 获取新闻动态
 * @param {Object} data 
 */
export function find_news(data:any) {
  return axios.get(`/news/page`, { params: data })
}

/**
 * 获取新闻动态详情
 * @param {String} id 
 */
export function find_news_detail(id:string) {
  return axios.get(`/news/${id}`, { params: {id} })
}

/**
 * 获取验证码
 * @param {Object} data 
 */
export function find_captcha(data:any) {
  return axios.get(`/sign/captcha`, data)
}