
import Fade from 'react-reveal/Fade'
import QRCode  from 'qrcode.react'
import { DOWNLOAD }  from '@/utils/constants'
import style from '../style/Phone.module.css'

import bgImg from '@/assets/images/home/phoneBg.png'
import phone from '@/assets/images/home/phone.png'

import phoneCon from '@/assets/images/home/phoneCon.png'
import phone01 from '@/assets/images/home/phone01.png'
import phone02 from '@/assets/images/home/phone02.png'
import phone03 from '@/assets/images/home/phone03.png'
import phone04 from '@/assets/images/home/phone04.png'

function Phone(){
  
  const bg = {
    backgroundImage: `url(${bgImg})`
  }

  return (
    <div className={`flex_column_center max_width min_width item_top item_bottom`}>
      <div className={`min_width ${style.bg_con}`} style={bg}>
        <div className={`flex_row min_width content_space ${style.bg_about}`}>
          <Fade left>
            <div className={`flex_column ${style.bg_left}`}>
              <div className={`flex_column`}>
                <span className={`space_bottom ${style.title}`}>安百纳智能家居App</span>
                <span className={`${style.desc}`}>是一款智能家居软件，除了能随时随地控制家中各种电器设备，极大程度上减轻了操控负担，还能一键单击同时运行多个设备，定制专属场景。</span>
                <div className={`flex_column_center ${style.qr_box}`}>
                  <QRCode
                    className={`${style.qr_code}`}
                    value={DOWNLOAD}  //value参数为生成二维码的链接
                    size={158} //二维码的宽高尺寸
                    fgColor="#000000"  //二维码的颜色
                  />
                  <span className={style.qr_text}>扫码一键下载</span>
                </div>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className={`flex_column ${style.bg_right}`}>
              <img src={phone} alt='phone' className={style.phone} />
              <img src={phoneCon} alt='phone' className={style.phone_con} />
            </div>
          </Fade>
        </div>
      </div>

      <div className={`flex_row_center_between min_width ${style.desc_con}`}> 
        <Fade bottom>
          <div className={`flex_row content_space ${style.box_wrap}`}>
            <div className={style.box_column}>
              <div className={`${style.box} ${style.box_bottom} ${style.box_right}`}>
                <img src={phone01} alt='远程控制' className={style.icon} />
                <span className={style.icon_name}>远程控制</span>
                <span className={style.icon_desc}>出门在外，随时随地管理家庭安全</span>
              </div>
              <div className={style.box}>
                <img src={phone03} alt='家庭管理' className={style.icon} />
                <span className={style.icon_name}>家庭管理</span>
                <span className={style.icon_desc}>用心守护家的每一面</span>
              </div>
            </div>
            <div className={style.box_column}>
              <div className={`${style.box} ${style.box_bottom} ${style.box_top}`}>
                <img src={phone02} alt='一键启动' className={style.icon} />
                <span className={style.icon_name}>一键启动</span>
                <span className={style.icon_desc}>家中所有设备或自定义场景一键开启</span>
              </div>
              <div className={style.box}>
                <img src={phone04} alt='场景智能化' className={style.icon} />
                <span className={style.icon_name}>场景智能化</span>
                <span className={style.icon_desc}>让计划先人一步</span>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Phone