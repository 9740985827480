import Fade from 'react-reveal/Fade'
import Banner from '@/components/Banner'
import Introduce from './components/Introduce'
import Honor from './components/Honor'
import Team from './components/Team'

import type { TeamProps } from './components/Team'

import pic11 from '@/assets/images/componey/patent/1-1.jpg';
import pic12 from '@/assets/images/componey/patent/1-2.jpg';
import pic21 from '@/assets/images/componey/patent/2-1.jpg';
import pic22 from '@/assets/images/componey/patent/2-2.jpg';
import pic23 from '@/assets/images/componey/patent/2-3.jpg';
import pic31 from '@/assets/images/componey/patent/3-1.jpg';
import pic32 from '@/assets/images/componey/patent/3-2.jpg';
import pic33 from '@/assets/images/componey/patent/3-3.jpg';
import pic34 from '@/assets/images/componey/patent/3-4.jpg';
import pic35 from '@/assets/images/componey/patent/3-5.jpg';

import chen from '@/assets/images/componey/team/chen.png'
import wang from '@/assets/images/componey/team/wang.png'
import zhao from '@/assets/images/componey/team/zhao.jpg'

function Componey() {
  const banner = 'https://static.a-bene.com/abene-static/784aa8b4458bd3be3e5c58fc62373fa3.png'
  
  // 荣耀资质
  const honorData:string[] = [
    pic11,
    pic12,
    pic21,
    pic22,
    pic23,
    pic31,
    pic32,
    pic33,
    pic34,
    pic35
  ]

  // 团队
  const teamData:TeamProps[] = [
    {
      image:chen,
      name:'陈伟雄',
      post:'创始人',
      content:`安百纳智慧家居的创始人，现任职深圳市IT服务商协会-创始人、深圳市智能家居研究院-副院长、
      深圳市软装行业协会-副会长、深圳市安百纳科技有限公司-董事长、深圳市安百纳智能实业有限公司-董事长。
      `
    },
    {
      image:wang,
      name:'王彩健',
      post:'副总经理',
      content:`从事专业舞台灯光、智能家居行业15余年，有着15年的工程安装调试经验；精通舞台、智能家居控制技能知识；
      同时也是一名专业的智能家居技术工程师培训讲师；已培养全国4000+名工程师。实施项目工程，擅长领域：舞台灯光、智能家居控制技术与应用。
      `
    },
    {
      image:zhao,
      name:'赵雪',
      post:'副总经理',
      content:`从事IT系统集成行业十余年，对教育行业，安防防范系统安装维护员，信息网络安全专业技术人员、
      信息安全保障人员、ISO9001:2008国家认可内部审核员，医疗行业以及轨道交通公安通信有着极丰富的项目管理经验。
      `
    }
  ]

  return (
    <>
      <Fade top>
        { Banner(banner) }
      </Fade>
      <Introduce />
      { Honor(honorData) }
      { Team(teamData) }
    </>
  )
}

export default Componey