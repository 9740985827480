import React from 'react'
import Fade from 'react-reveal/Fade'
import style from '../style/Security.module.css'

import security01 from '@/assets/images/community/security01.png'
import security02 from '@/assets/images/community/security02.png'
import security03 from '@/assets/images/community/security03.png'

function Security() {
  return (
    <React.Fragment>
      <div className={`container space_bottom content_space ${style.title}`}>智慧安防管理</div>

      <div className={`flex_column_center min_width ${style.bg}`}>
        <div className={`flex_row_center_between ${style.row}`}>
          <Fade left>
          <img src={security01} alt={security01} className={style.img} />
          </Fade>
          <Fade right>
          <div className={`flex_column ${style.con}`}>
            <span className={`space_bottom ${style.con_title}`}>智能门禁系统</span>
            <span className={style.con_desc}>支持包括远程控制、指纹识别、密码输入、刷卡识别在内的多种门禁识别方式，可满足不同场景不同用户的需求；支持人员出入管理、考勤管理、访客管理等多种管理模式，在保障人流畅通的同时，结合楼宇的管理制度实现灵活的安全管理。</span>
          </div>
          </Fade>
        </div>
      </div>

      <div className={`flex_column_center min_width ${style.bg2}`}>
        <div className={`flex_row_center_between ${style.row}`}>
          <Fade left>
          <div className={`flex_column ${style.con}`}>
            <span className={`space_bottom ${style.con_title}`}>楼宇对讲系统</span>
            <span className={style.con_desc}>楼宇对讲系统支持访客对住户、访客、住户对管理中心及管理中心对访客的三方双向通话，可实现安全的访客管理和便捷的信息发布功能。</span>
          </div>
          </Fade>
          <Fade right>
          <img src={security02} alt={security02} className={style.img} />
          </Fade>
        </div>
      </div>

      <div className={`flex_column_center min_width ${style.bg}`}>
        <div className={`flex_row_center_between ${style.row}`}>
          <Fade left>
          <img src={security03} alt={security03} className={style.img} />
          </Fade>
          <Fade right>
          <div className={`flex_column ${style.con}`}>
            <span className={`space_bottom ${style.con_title}`}>大屏控制系统</span>
            <span className={style.con_desc}>支持大屏拼接，可根据需要灵活拼接屏幕矩阵。支持通过综合管理平台对屏幕墙进行管理，可实现开窗、缩放、自定义场景等应用，方便管理人员管理画面细节进行查看。支持预案管理，可在告警发生时按预案自动弹窗并聚焦画面，吸引管理人员关注。</span>
          </div>
          </Fade>
        </div>
      </div>
    </React.Fragment >
  )
}

export default Security