import Fade from 'react-reveal/Fade'
import style from '../style/Introduce.module.css'

import hotel01 from '@/assets/images/hotel/hotel01.png'
import hotel02 from '@/assets/images/hotel/hotel02.png'
import hotel03 from '@/assets/images/hotel/hotel03.png'
import hotel04 from '@/assets/images/hotel/hotel04.png'
import hotel05 from '@/assets/images/hotel/hotel05.png'
import hotel06 from '@/assets/images/hotel/hotel06.png'
import hotel07 from '@/assets/images/hotel/hotel07.png'
import hotel08 from '@/assets/images/hotel/hotel08.png'
import bg from '@/assets/images/hotel/bg04.png'

function Introduce() {
  return (
    <div className={`flex_row_center_center container ${style.box}`}>
      <div className={`space_bottom container content_space ${style.title}`}>应用介绍</div>
      <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <img src={hotel01} alt={hotel01} className={style.img} />
        </Fade>

        <Fade right>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>智能照明</span>
            <span className={style.con_desc}>实现对客房灯光照明的智能管理，自定义情景，根据会客、回家、休息、卫浴等场景调节灯光颜色与亮度实现灯光照明自动控制，满足不同时段中不同的情景需求，创造个性化、艺术化、舒适、高雅的家庭光环境。</span>
          </div>
        </Fade>
      </div>

      <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>智能家电</span>
            <span className={style.con_desc}>配合智能插座、红外转发器，通过手机远程控制或定时控制客房内的电器设备，并实现远程控制、语音控制、情景联动控制以及人体感应等多种方式为客人营造一个舒适、便利的居住环境。</span>
          </div>
        </Fade>

        <Fade right>
          <img src={hotel02} alt={hotel02} className={style.img} />
        </Fade>
      </div>

      <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <img src={hotel03} alt={hotel03} className={style.img} />
        </Fade>

        <Fade right>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>智能窗帘</span>
            <span className={style.con_desc}>通过手动控制、定时控制、远程控制、环境亮度变化控制等多种控制方式，对客房内所有的电动窗帘进行控制，发送不同的指令，实现窗帘开合度的自动调节，形成室内活动正好所需的光照及环境氛围。</span>
          </div>
        </Fade>
      </div>

      <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>智能影音</span>
            <span className={style.con_desc}>配合背景音乐、电视、投影机、投影幕布、窗帘、各种音视频等设备，根据设定的情景启动进入影院模式，灯光自动调节至最佳氛围，窗帘会关闭，音视频设备会自动开启，享受高品质的音频节目，感受先进的影音体验。</span>
          </div>
        </Fade>

        <Fade right>
          <img src={hotel04} alt={hotel04} className={style.img} />
        </Fade>
      </div>

      <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <img src={hotel05} alt={hotel05} className={style.img} />
        </Fade>

        <Fade right>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>智能安防</span>
            <span className={style.con_desc}>在APP上设置密码、更改密码和远程开锁，智能设备自动判断客房内安全状况，多种报警方式，可设定防盗、防火、防水等多种安防场景，当警报响起时，APP推送安防信息，第一时间处理，为宾客提供全方位保障。</span>
          </div>
        </Fade>
      </div>

      <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>智能客服</span>
            <span className={style.con_desc}>在app可以呼叫客房服务，享受订餐、订台等服务，更多服务需寻找第三方合作，随时跟踪服务情况，让酒店的服务更快捷。</span>
          </div>
        </Fade>

        <Fade right>
          <img src={hotel06} alt={hotel06} className={style.img} />
        </Fade>
      </div>

      <Fade right>
        <img src={bg} alt={bg} className={style.bg} />
      </Fade>

      <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>睡眠模式</span>
            <span className={style.con_desc}>安心的进入梦乡，享受美好的睡眠</span>
            <span className={style.con_desc}>当启动“睡眠模式”，灯光自动关闭，小夜灯亮起，门铃自动切换勿扰模式，避免受到打扰。</span>
          </div>
        </Fade>

        <Fade right>
          <img src={hotel07} alt={hotel07} className={style.img} />
        </Fade>
      </div>

      <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <img src={hotel08} alt={hotel08} className={style.img} />
        </Fade>

        <Fade right>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>卫浴模式</span>
            <span className={style.con_desc}>冲刷疲劳，享受此刻温暖与舒适。</span>
            <span className={style.con_desc}>当检测有人进入洗手间，自动开启灯光照明、换气窗，魔镜进入工作状态，当客户站在魔镜前，将自动获取睡眠质量、天气状况、空气质量以及咨询等信息。</span>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Introduce