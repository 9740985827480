import Fade from 'react-reveal/Fade'
import style from '../style/Introduce.module.css'

import introduce01 from '@/assets/images/office/introduce01.png'
import introduce02 from '@/assets/images/office/introduce02.png'
import introduce03 from '@/assets/images/office/introduce03.png'
import introduce04 from '@/assets/images/office/introduce04.png'
import introduce05 from '@/assets/images/office/introduce05.png'

function Introduce() {
  return (
    <div className={`${style.content}`}>
      <div className={`space_bottom container content_space ${style.title}`}>应用介绍</div>

      <div className={`flex_row_center_center container ${style.box}`}>
        <div className={`flex_row_center ${style.row} `}>
          <Fade left>
            <img src={introduce01} alt={introduce01} className={style.img} /> 
          </Fade>

          <Fade right>
            <div className={`flex_column_start_center ${style.con}`}>
              <span className={style.con_title}>上班前办公室环境自动调节</span>
              <span className={style.con_desc}>自定义时间启动环境适应配置</span>
              <span className={style.con_desc}>自动监测办公室的环境状态</span>
              <span className={style.con_desc}>根据设置自动调节</span>
            </div>
          </Fade>
        </div>

        <div className={`flex_row_center ${style.row}`}>
          <Fade left>
            <div className={`flex_column_start_center ${style.con}`}>
              <span className={style.con_title}>免钥匙开门上班撤防</span>
              <span className={style.con_desc}>支持指纹、钥匙、密码、卡开门</span>
              <span className={style.con_desc}>门锁使用超C级锁芯，有效防止撬锁芯、无法配钥匙</span>
            </div>
          </Fade>

          <Fade right>
            <img src={introduce02} alt={introduce02} className={style.img} />
          </Fade>
        </div>

        <div className={`flex_row_center ${style.row}`}>
          <Fade left>
            <img src={introduce03} alt={introduce03} className={style.img} />
          </Fade>

          <Fade right>
            <div className={`flex_column_start_center ${style.con}`}>
              <span className={style.con_title}>会议室智能模式</span>
              <span className={style.con_desc}>无纸化会议系统</span>
              <span className={style.con_desc}>高清大屏幕系统</span>
              <span className={style.con_desc}>会议灯光系统</span>
              <span className={style.con_desc}>会议音响系统</span>
              <span className={style.con_desc}>会议预约系统</span>
            </div>
          </Fade>
        </div>

        <div className={`flex_row_center ${style.row}`}>
          <Fade left>
            <div className={`flex_column_start_center ${style.con}`}>
              <span className={style.con_title}>办公室下班模式</span>
              <span className={style.con_desc}>可自定义时间自动切断用电设备电源</span>
              <span className={style.con_desc}>自动关闭环境控制设备</span>
              <span className={style.con_desc}>安防设备布防自动启动</span>
            </div>
          </Fade>

          <Fade right>
            <img src={introduce04} alt={introduce04} className={style.img} />
          </Fade>
        </div>

        <div className={`flex_row_center ${style.row}`}>
          <Fade left>
            <img src={introduce05} alt={introduce05} className={style.img} />
          </Fade>

          <Fade right>
            <div className={`flex_column_start_center ${style.con}`}>
              <span className={style.con_title}>远程控制模式</span>
              <span className={style.con_desc}>远程手机查看是否还有人加班、加班状态。</span>
              <span className={style.con_desc}>远程检查办公室设备状态，实现一键全关闭。</span>
              <span className={style.con_desc}>单独控制办公室单个设备的状态。</span>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Introduce