import Fade from 'react-reveal/Fade'

import style from '../style/SystemEnvironment.module.css'

import environment01 from '@/assets/images/family/environment01.png'
import environment02 from '@/assets/images/family/environment02.png'
import environment03 from '@/assets/images/family/environment03.png'

const items = [
  {
    title: '远程控制温度',
    subtitle: '夏天回家，清凉徐来',
    desc: '下班路上，通过安百纳 APP，一键远程控制空调的开关、温度的调节，到家无需等待，舒适即刻享受。',
    style: { backgroundImage: `url(${environment01})` }
  }, {
    title: '环境智能管理',
    subtitle: '恒温恒湿，舒心生活',
    desc: '智能场景机制将传感器采集到的数据结合用户的使用习惯自动开关窗户或空调，调节家中的温湿度。',
    style: { backgroundImage: `url(${environment02})` }
  }, {
    title: '暖通自动定时',
    subtitle: '温暖长存，能耗减半',
    desc: '根据作息规律，提前设置好开关时间、模式、重复周期等，在无感知的情况下按需调配，兼顾舒适与节能。',
    style: { backgroundImage: `url(${environment03})` }
  }
]

function SystemEnvironment() {
  return (
    <Fade bottom>
      <div id='environment' className={`min_width max_width flex_row_center_between`}>
        {
          items.map((item, index) => {
            return (
              <div key={index} className={`${style.box}`}>
                <div className={style.bg_box}>
                  <div className={style.bg} style={item.style}></div>
                </div>
                <div className={`flex_column ${style.con}`}>
                  <span className={`space_bottom ${style.title}`}>{ item.title }</span>
                  <span className={`space_bottom ${style.subtitle}`}>{ item.subtitle }</span>
                  <span className={`${style.desc}`}>{ item.desc }</span>
                </div>
              </div>
            )
          })
        }
      </div >
    </Fade>
  )
}

export default SystemEnvironment