import { useEffect, useState, FC } from 'react'
import { useHistory, Link, withRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Fade from 'react-reveal/Fade'
import {find_menus} from '@/api/product'
import { changeProductCurrent } from '@/store/actions/productAction'

import style from './Footer.module.css'
import qrCode from '@/assets/images/public/qrCode.jpg' 

import type { RouteProps } from 'react-router-dom'

export interface ProductMenusProps {
  id:string
  name:string
}

const Footer: FC = (route:RouteProps) => {

  const History = useHistory()
  const dispatch = useDispatch()
  const [productMenus, setProductMenus] = useState([] as ProductMenusProps[])

  useEffect(() => {
    if (productMenus.length === 0) {
      handleGetProductMenus()
    }
  }, [productMenus])

  // 获取产品菜单数据 
  const handleGetProductMenus = () => {
    find_menus({}).then((response:any) => {
      if (response?.code === 200) {
        const data = response.list?.slice?.(0,6)
        setProductMenus(data)
      }
    })
  } 

  // 点击
  const handleClickProduct = (routeName:string, id:string = '') => {
    const { pathname } = route?.location ?? {}
    if (routeName === '/product' && pathname === '/product') {
      window.scrollTo({
        top: 0, 
        behavior: "smooth" 
      })
    }
    dispatch(changeProductCurrent(id))
    return History.push(routeName, {id})
  }

  return (
    <Fade bottom>
      <div className={`min_width ${style.contain}`}>
        <div className={`content ${style.content}`}>
          <Fade left>
            <div className={style.left}>
              <div className={style.nav_menu}>
                <div onClick={() => {handleClickProduct('/product')}} className={`${style.title} ${style.href}`}>产品中心</div>
                {
                  productMenus.map((item:any, index) => {
                    return <div onClick={() => {handleClickProduct('/product', item.id)}} key={index} className={`${style.subtitle} ${style.href}`}>{ item.name }</div>
                  })
                }
              </div>
              <div className={style.nav_menu}>
                <Link to="/project/family" >
                  <div className={`${style.title} ${style.href}`}>解决方案</div>
                </Link>
                <Link to="/project/family">
                  <div className={`${style.subtitle} ${style.href}`}>智慧家庭</div>
                </Link>
                <Link to="/project/video">
                  <div className={`${style.subtitle} ${style.href}`}>智慧影音</div>
                </Link>
                <Link to="/project/office">
                  <div className={`${style.subtitle} ${style.href}`}>智慧办公</div>
                </Link>
                <Link to="/project/hotel">
                  <div className={`${style.subtitle} ${style.href}`}>智慧酒店</div>
                </Link>
                <Link to="/project/community">
                  <div className={`${style.subtitle} ${style.href}`}>智慧社区</div>
                </Link>
                <Link to="/project/park">
                  <div className={`${style.subtitle} ${style.href}`}>智慧园区</div>
                </Link>
              </div>

              <div className={style.nav_menu}>
                <Link to="/about/componey">
                  <div className={`${style.title} ${style.href}`}>了解安百纳</div>
                </Link>
                <Link to="/about/componey">
                  <div className={`${style.subtitle} ${style.href}`}>关于我们</div>
                </Link>
                <Link to="/about/news">
                  <div className={`${style.subtitle} ${style.href}`}>新闻资讯</div>
                </Link>
                <Link to="/about/join">
                  <div className={`${style.subtitle} ${style.href}`}>加入我们</div>
                </Link>
              </div>
            </div>
          </Fade>
          <Fade right>
            <div className={style.right}>
              <span className={style.title}>联系我们</span>
              <span className={style.subtitle}>400-870-1668</span>
              <span className={style.subtitle}>7*24小时全天服务</span>
              <span className={style.subtitle}>业务合作：app@a-bene.com</span>
              <span className={style.subtitle}>公司地址:深圳｜韶关 </span>
              
              <div className={style.qr_content}>
                <img src={qrCode} alt='qrCode' className={style.qr_code} />
                <div className={style.qr_text}>
                  <span className={style.qr_title}>关注“安百纳智能”</span>
                  <span className={style.qr_subtitle}>第一时间获取行业资讯</span>
                </div>
              </div>
            </div>
          </Fade>
        </div>
        <span className={style.copyright}>© 深圳市安百纳智能实业有限公司  版权所有 
		< a href="https://beian.miit.gov.cn/" className={style.link}>粤ICP备 19080785</ a>
		</span>
      </div>
    </Fade>
  )
}
export default withRouter(Footer)
