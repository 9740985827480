import { FC } from "react";
import { Result, Button } from 'antd'

const NotFound: FC = () => {
  return (
    <Result
      status="404"
      title="404"
      subTitle="很抱歉，您访问的页面不存在。"
      extra={<Button type="primary" href="/">返回首页</Button>}
    />
  )
}

export default NotFound