import { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { Breadcrumb, Spin } from 'antd'
import Fade from 'react-reveal/Fade'
import NotData from '@/components/NotData'

import {find_news_detail, find_news } from '@/api/website'
import { getLocalData, setLocalData } from '@/utils/storage'
import { formatDate } from '@/utils/utils'
import { NEWS_INFO } from '../constants'

import style from './index.module.css'

import type { NewsProps, NewsItem } from '../index'

interface NewsInfo {
  id:string
  title:string
  lastModifiedAt: Date
  content:{[key:string]:any}
}

function NewsDetails({match}:any) {

  const [isLoading, setLoading] = useState(false)
  // 当前数据
  const [info, setInfo] = useState({} as NewsInfo)

  // 上一个
  const [prevInfo, setPrevInfo] = useState({} as NewsItem)
  // 下一个
  const [nextInfo, setNextInfo] = useState({} as NewsItem)

  useEffect(() => {
    const { id } = match?.params
    if (id) {
      setLoading(true)
      find_news_detail(id).then((response:any) => {
        if (response && response.code === 200) {
          const data:NewsInfo = response.data
          document.title = data.title ?? '新闻详情'
          setInfo(data)
          formatNewsList(id)
        }
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [match])

  // 处理上一页/下一页
  const formatNewsList = (id:string) => {
    // 列表数据
    const newsInfo:NewsProps = getLocalData(NEWS_INFO) ?? {}
    if(!newsInfo?.records?.length) return loadNews(1,'last')

    const { records, first, last, current } = newsInfo
    const currentIndex = records?.findIndex?.(item => item.id === id)

    if(currentIndex !== -1){
      // 第一个
      if(currentIndex === 0){
        // 第一页第一个
        if(first){
          setPrevInfo({} as NewsItem)
          setNextInfo(records[currentIndex + 1])
        }else{
          loadNews(Number(current) - 1, 'first')
        }
      }else if(currentIndex === (records?.length - 1)){
        // 最后一页最后一个
        if(last){
          setPrevInfo(records[currentIndex - 1])
          setNextInfo({} as NewsItem)
        }else{
          loadNews(Number(current) + 1, 'last')
        }
      }else{
        setPrevInfo(records[currentIndex - 1])
        setNextInfo(records[currentIndex + 1])
      }

    }else{
      loadNews(Number(current) + 1, 'last')
    }
  }

  // 获取news数据
  const loadNews = (pageNumber:number, type: 'first' | 'last')=> {
    const params = {
      application: 'Website',
      pageNumber,
      pageSize: 7,
      platform: 'PC',
      type: 'Notice'
    }
    find_news(params).then((response:any) => {
      if (response?.code === 200) {
        const { data }:{data:NewsProps} = response 
        // 列表数据
        const newsInfo:NewsProps = getLocalData(NEWS_INFO) ?? {records:[]}
        if(type === 'last'){
          const records = formatArray(newsInfo?.records, data.records)
          setLocalData(NEWS_INFO, {...data, records})
        }else{
          const records = formatArray(data?.records, newsInfo.records)
          setLocalData(NEWS_INFO, {...data, records})
        }
        setTimeout(() => {
          formatNewsList(match?.params?.id)
        }, 1000)
      }
    })
  }

  const formatArray = (arr:any[], newArr:any[]) => {
    const res = [...arr]
    newArr.forEach((newItem) => {
      if(!arr.some((item) => newItem.id === item.id)){
        res.push(newItem)
      }
    })
    return res
  }
    
  return (
    <Spin spinning={isLoading}>
      <div className={`${style.content}`}>
        <Fade top>
          <Breadcrumb className={`${style.bread}`}>
            <Breadcrumb.Item>
              <Link to="/"><span className={style.href}>首页</span></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/about/contact"><span className={style.href}>关于我们</span></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/about/news"><span className={style.href}>新闻动态</span></Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>正文</Breadcrumb.Item>
          </Breadcrumb>
        </Fade>

        <div className={`flex_row_center ${style.date_box}`}>
          <span>{ formatDate(info.lastModifiedAt, 'YYYY-MM-DD') }</span>
          <span className={style.source}>by安百纳智慧家</span>
        </div>
      </div>
      
      { 
        info?.content?.content
        ? <div className='min_width flex_column_center'>
            <div className={style.desc} dangerouslySetInnerHTML={{__html: info?.content?.content}} />
          </div>
        : <div className='min_width flex_column_center'>
            <NotData />
          </div>
      }

      <Fade top>
        <div className={`min_width flex_row_center_between ${style.prev_next}`}>
          <div className={style.con_title}>
            <span className={style.label}>上一篇:</span>
            
            <span className={style.article}>
              { prevInfo?.id 
                ? <Link to={`/about/news/${prevInfo.id}`}>
                    <span className={style.href}>{ prevInfo.title }</span> 
                  </Link>
                : '暂无数据' 
              }
            </span>
          </div>
          <div className={style.con_title}>
            <span className={style.label}>下一篇:</span>
            <span className={style.article}>
              { nextInfo?.id 
                ? <Link to={`/about/news/${nextInfo.id}`}>
                    <span className={style.href}>{ nextInfo.title }</span> 
                  </Link>
                : '暂无数据' 
              }
            </span>
          </div>
        </div>
      </Fade>
    </Spin>
  )
}

export default withRouter(NewsDetails)