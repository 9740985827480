import * as types from '../constants/product'
import type { RootAction } from '../types'
// product state
const defaultState = {
  current: ''
}

const defaultActions = (state = defaultState, { type, payload }:RootAction) => {
  const newState = Object.assign(state)
  switch (type) {
    case types.CHANGE_PRODUCT_CURRENT:
      newState.current = payload
      break

    default:
      return state
  }
  return newState
}

export default defaultActions
