import style from '../style/Description.module.css' 

function Description(data:string, image:string ) {
  return (
    <div id='Description' className={`container flex_row_center_between min_width ${style.box} ${data ? 'display_block' : 'display_none'}`}>
      <img src={image} alt='产品描述' className={style.img} />
      <div className={`flex_column`}>
        <div className={`flex_column ${style.con}`}>
          <span className={`space_bottom ${style.title}`}>产品描述</span>
          <div id='data' className={style.desc} dangerouslySetInnerHTML={{__html: data}} />
        </div>
      </div>
    </div>
  )
}

export default Description