
import { FC, useState, useEffect } from "react";
import { useDispatch } from 'react-redux'
import { useHistory, Link, withRouter } from 'react-router-dom'
import { Menu } from 'antd'
import Fade from 'react-reveal/Fade'
import { changeProductCurrent } from '@/store/actions/productAction'

import style from './Header.module.css'
import logo from '@/assets/images/public/logo.png'

import type  { RouteProps } from 'react-router-dom'

const { SubMenu } = Menu

const Header: FC = (route:RouteProps) => {

  const History = useHistory()
  const dispatch = useDispatch()
  const [current, setCurrent] = useState('/')

  useEffect(() => {
    if (route?.location?.pathname !== current) {
      const pathname = route?.location?.pathname || ''
      setCurrent(pathname)
    }
  }, [current, route])

  // 点击菜单
  const handleClick = ({key}:{key:string}) => {
    setCurrent(key)
    History.push(key)
    if(key === '/product'){
      dispatch(changeProductCurrent(''))
    }
  }

  return (
    <Fade top>
      <div className={`min_width ${style.contain}`}>
        <div className={`content_space min_width ${style.content}`}> 
          <div>
            <Link to="/"><img className={style.logo} src={logo} alt="安百纳logo" /></Link>
          </div>
          <div className={style.nav_menu}>
            <Menu className={style.menu_wrap} selectedKeys={[current]} mode="horizontal"  onClick={handleClick}>
              <Menu.Item key='/' className={style.item}>首页</Menu.Item>
              <Menu.Item key='/product' className={style.item}>产品中心</Menu.Item>
              <SubMenu key="/project" title="解决方案" className={style.sub_item}>
                <Menu.Item key="/project/family">智慧家庭</Menu.Item>
                <Menu.Item key="/project/video">智慧影音</Menu.Item>
                <Menu.Item key="/project/office">智慧办公</Menu.Item>
                <Menu.Item key="/project/hotel">智慧酒店</Menu.Item>
                <Menu.Item key="/project/community">智慧社区</Menu.Item>
                <Menu.Item key="/project/park">智慧园区</Menu.Item>
              </SubMenu>
              <Menu.Item key='/merchants' className={style.item}>招商加盟</Menu.Item>
              <SubMenu key="/about" title="关于我们" className={style.sub_item}>
                <Menu.Item key="/about/componey">公司简介</Menu.Item>
                <Menu.Item key="/about/news">新闻动态</Menu.Item>
                <Menu.Item key="/about/partner">合作伙伴</Menu.Item>
                <Menu.Item key="/about/join">加入我们</Menu.Item>
                <Menu.Item key="/about/contact">联系我们</Menu.Item>
              </SubMenu>
            </Menu>
          </div>
        </div>
      </div>
    </Fade>
  )
}

export default withRouter(Header)