import { Map, Marker } from 'react-amap'
import { EnvironmentFilled } from '@ant-design/icons'
import Fade from 'react-reveal/Fade'

import style from '../style/Method.module.css'
import contact from '@/assets/images/about/contact.png'
const amapkey = '3b423e063fb855769ff6b33455492f3c'
const position = { longitude: 114.114494, latitude: 22.654873 }

function Method() {
  return (
    <div className={`content content_space flex_column item_top ${style.content}`}>
      <Fade bottom>
        <span className={`space_bottom ${style.title}`}>联系方式</span>
        <span className={`${style.componey}`}>深圳市安百纳智能实业有限公司 </span>
        <span className={style.desc}>地址：深圳市龙岗区甘李路2号中盛科技园6栋5层</span>
        <span className={`${style.desc}`}>合作热线：0755-82884431(周一至周日9:00-21:00)</span>
      </Fade>

      <Fade top>
        <div className={style.map}>
          <Map amapkey={amapkey} plugins={['ToolBar']} zoom={18} center={position}>
            <Marker position={position}>
              <EnvironmentFilled className={style.position} style={{fontSize: '20px'}} />
            </Marker>
          </Map>
        </div>
      </Fade>
      
      <Fade bottom>
        <span className={`item_top ${style.componey}`}>韶关安百纳体验馆</span>
        <span className={style.desc}>地址：广东省韶关前进建材城C2座三楼16-17号</span>
        <span className={`${style.desc}`}>合作热线：0751-89133891(周一至周日9:00-21:00)</span>
      </Fade>

      <img src={contact} alt={contact} className={style.img} />
    </div>
  )
}

export default Method