/*
 * @Description: 定义路由
 */

// 首页
import Home from '@/pages/Home'

// 产品
import Product from '@/pages/Product'
import ProductDetail from '@/pages/Product/detail'

// 解决方案
import ProjectFamily from '@/pages/Project/family'
import ProjectVideo from '@/pages/Project/video'
import ProjectOffice from '@/pages/Project/office'
import ProjectHotel from '@/pages/Project/hotel'
import ProjectCommunity from '@/pages/Project/community'
import ProjectPark from '@/pages/Project/park'

// 招商加盟
import Merchants from '@/pages/Merchants'

// 关于我们
import AboutComponey from '@/pages/About/componey'
import AboutNews from '@/pages/About/news'
import AboutNewsDetail from '@/pages/About/news/detail'
import AboutContact from '@/pages/About/contact'
import AboutJoin from '@/pages/About/join'
import AboutPartner from '@/pages/About/partner'

// 错误页面
import NotFound from '@/pages/Error/404'

// 布局
import Layout from '@/layouts'
import ChildrenLayout from '@/layouts/ChildrenLayout'

// 给路由定义类型接口
export interface RouteOption {
  path:string,
  redirect?: string,
  component?: any,
  exact?: boolean,
  meta?:{
    title:string
  }
  children?: RouteOption[]
}

const routes: RouteOption[] = [
  {
    path:'/404',
    component: NotFound,
    exact: true
  },
  { 
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        component: Home,
        exact: true,
        meta:{
          title:'安百纳官网'
        }
      },
      {
        path: '/product',
        component: ChildrenLayout,
        children:[
          {
            path: '/product',
            component: Product,
            exact: true,
            meta:{
              title:'产品中心'
            },
          },
          {
            path: '/product/:id',
            component: ProductDetail,
            exact: true,
            meta:{
              title:'产品详情'
            }
          },
        ]
      },
      {
        path: '/project',
        component: ChildrenLayout,
        children:[
          {
            path: '/project/family',
            component: ProjectFamily,
            exact: true,
            meta:{
              title:'智慧家庭'
            }
          },
          {
            path: '/project/video',
            component: ProjectVideo,
            exact: true,
            meta:{
              title:'智慧影音'
            }
          },
          {
            path: '/project/office',
            component: ProjectOffice,
            exact: true,
            meta:{
              title:'智慧办公'
            }
          },
          {
            path: '/project/hotel',
            component: ProjectHotel,
            exact: true,
            meta:{
              title:'智慧酒店'
            }
          },
          {
            path: '/project/community',
            component: ProjectCommunity,
            exact: true,
            meta:{
              title:'智慧社区'
            }
          },
          {
            path: '/project/park',
            component: ProjectPark,
            exact: true,
            meta:{
              title:'智慧园区'
            }
          },
        ]
      },
      {
        path: '/merchants',
        component: Merchants,
        exact: true,
        meta:{
          title:'招商加盟'
        }
      },
      {
        path:'/about',
        component: ChildrenLayout,
        children:[
          {
            path: '/about/componey',
            component: AboutComponey,
            exact: true,
            meta:{
              title:'公司简介'
            }
          },
          {
            path: '/about/news',
            component: ChildrenLayout,
            children:[
              {
                path: '/about/news',
                component: AboutNews,
                exact: true,
                meta:{
                  title:'新闻动态'
                }
              },
              {
                path: '/about/news/:id',
                component: AboutNewsDetail,
                exact: true,
                meta:{
                  title:'新闻详情'
                }
              },
            ]
          },
          {
            path: '/about/partner',
            component: AboutPartner,
            exact: true,
            meta:{
              title:'合作伙伴'
            }
          },
          {
            path: '/about/join',
            component: AboutJoin,
            exact: true,
            meta:{
              title:'加入我们'
            }
          },
          {
            path: '/about/contact',
            component: AboutContact,
            exact: true,
            meta:{
              title:'关于我们'
            }
          },
        ]
      },
    ]
  }
]

export default routes