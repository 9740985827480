import Fade from 'react-reveal/Fade'
import style from '../style/Introduce.module.css'

import img from '@/assets/images/community/bg.png'
import community01 from '@/assets/images/community/community01.png'
const bg = { backgroundImage: `url(${img})` }

function Introduce() {
  return (
    <>
      <Fade top>
      <div className={`container space_bottom content_space ${style.title}`}>智慧物业管理</div>
      </Fade>

      <Fade top>
      <div className={`flex_column_center_center min_width ${style.bg}`} style={bg}>
        <div className={`flex_column_center_center ${style.bg_con}`}>
          <span className={`space_bottom ${style.bg_title}`}>智慧物业管理系统</span>
          <span className={style.bg_desc}>包括物业信息推送、通知发布、物业收费缴费、停车场收费缴费、物损报修、物品租用、访客管理、社区管理等。其中社区管理包含社区活动、社区新闻、社区朋友圈、二手交易、房屋租赁、家政服务、装饰装修服务等，丰富的管理功能提高物业管理效率的同时也提升了业主的入住体验。</span>
        </div>
      </div>
      </Fade>

      <Fade top>
      <div className={`container content_space space_bottom ${style.title}`}>智慧综合管理后台</div>
      </Fade>

      <div className={`min_width ${style.box_bg}`}>
        <div className={`flex_row_center_between container content_space ${style.box}`}>
          <Fade left>
          <div className={`flex_column ${style.box_con}`}>
            <span className={`space_bottom ${style.con_title}`}>智慧综合管理后台</span>
            <span className={`${style.con_desc}`}>综合软件管理平台为各个子模块提供了一个统一的管理接口，通过该接口管理者可以很方便的查看到各个子模块的各项统计信息，并可以通过平台快速的访问各个子模块，实现对子模块的查询和控制。</span>
          </div>
          </Fade>

          <Fade right>
          <img src={community01} alt={community01} className={style.img} />
          </Fade>
        </div>
      </div>
    </>
  )
}

export default Introduce