import Fade from 'react-reveal/Fade'
import style from '../style/Business.module.css'

import business01 from '@/assets/images/park/business01.png'
import business02 from '@/assets/images/park/business02.png'
import business03 from '@/assets/images/park/business03.png'
import business04 from '@/assets/images/park/business04.png'

function Business() {
  return (
    <div className={`flex_column_center min_width ${style.content}`}>
      <span className={`space_bottom ${style.title}`}>园区业务</span>

      <div className={`flex_row_center container  ${style.box}`}>
        <Fade left>
        <img src={business01} alt={business01} className={style.img} />
        </Fade>

        <Fade right>
        <div className={`flex_column_start_center ${style.con}`}>
          <span className={style.con_title}>智慧管理</span>
          <span className={style.con_subtitle}>构建园区管理的智慧大脑</span>
          <span className={style.con_desc}>凭借20余年的园区运营管理经验和信息化建设经验，利用先进的技术，为园区管理方提供“一站式智慧化管理” 解决方案，满足园区管理方智慧化的运营监控指挥管理、招商管理、客户全生命周期管理、资产全生命周期管理、协同办公管理等需求，吸收一流园区的管理智慧，促进园区管理与运营统一、规范、有序协同的进行。全方位的“人、财、物” 的智能化管理，助力园区提早实现更高大上。</span>
        </div>
        </Fade>

        <Fade left>
        <div className={`flex_column_start_center ${style.con}`}>
          <span className={style.con_title}>智慧服务</span>
          <span className={style.con_subtitle}>打造立体化的智慧园区服务门户</span>
          <span className={style.con_desc}>打造线上、线下一体化智慧服务平台，基于多终端构建前后端一体化的园企互动服务体系；打通园区管理与企业线上互通渠道，提升企业满意度；建立需求受理响应机制，确保服务品质。</span>
          <span className={style.con_desc}>建设智慧的园区公共服务、企业在线孵化服务等，实现工作、居住、消费等服务环境的智慧体验、智慧互动、以及个性化展示。</span>
        </div>
        </Fade>

        <Fade right>
        <img src={business02} alt={business02} className={style.img} />
        </Fade>

        <Fade left>
        <img src={business03} alt={business03} className={style.img} />
        </Fade>
          
        <Fade right>
        <div className={`flex_column_start_center ${style.con}`}>
          <span className={style.con_title}>智慧基础</span>
          <span className={style.con_subtitle}>加速园区基础建设智慧化</span>
          <span className={style.con_desc}>安全、便捷、舒适、高效、节能的基础设施建设，实现园区万物互联，打破信息孤岛，建立园区高效连接的智慧通道。</span>
          <span className={style.con_desc}>实现智能绿色节能、智能停车、智能一卡通、智慧单兵巡更、BIM建设、园区云计算平台等，加速园区基础建设的智慧化。</span>
        </div>
        </Fade>

        <Fade left>
        <div className={`flex_column_start_center ${style.con}`}>
          <span className={style.con_title}>指挥决策</span>
          <span className={style.con_subtitle}>构建“一站式”决策支持及数据统计分析运营中心</span>
          <span className={style.con_desc}>根据园区管理的特点，为园区高层管理者定制的“一站式”决策支持及数据统计分析运营中心，利用大数据分析及边缘计算等先进的互联网技术，整合外部资源与内部数据，梳理有价值的数据源，进行汇聚、分析、呈现、决策、运营。</span>
          <span className={style.con_desc}>采用驾驶舱的实现形式，通过直观监测园区各种运营情况，并对异常指标进行预警、挖掘和分析，为园区高层管理者提供辅助决策支持，成为随需随用的“战略军师”。</span>
        </div>
        </Fade>

        <Fade right>
        <img src={business04} alt={business04} className={style.img} />
        </Fade>
      </div>
    </div>
  )
}

export default Business