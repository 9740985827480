import Fade from 'react-reveal/Fade'
import style from '../style/Advantage.module.css'

import advantage01 from '@/assets/images/community/advantage01.png'
import advantage02 from '@/assets/images/community/advantage02.png'
import advantage03 from '@/assets/images/community/advantage03.png'
import advantage04 from '@/assets/images/community/advantage04.png'

const list = [
  {
    title: '管理成本低',
    desc: '智能安防系统对社区房屋、车辆、人员等进行全方面的数据采集，有效降低管理成本。',
    style: { backgroundImage: `url(${advantage01})` }
  },
  {
    title: '人口精确采集',
    desc: '被动变主动，让居民主动登记办理开门权限，实现人口信息100%采集',
    style: { backgroundImage: `url(${advantage02})` }
  },
  {
    title: '精细化管理手段',
    desc: '建立一社一档、一屋一档、一人一档，实现精细化网格管理。',
    style: { backgroundImage: `url(${advantage03})` }
  },
  {
    title: '实时流量管控',
    desc: '强大的数据分析系统、实时对布控、聚赌、传销等人员进行管控。',
    style: { backgroundImage: `url(${advantage04})` }
  },
]

function Advantage() {
  return (
    <div className={`container ${style.content}`}>
      <div className={`space_bottom content_space ${style.title}`}>系统优势</div>

      <Fade bottom>
      <div className={`flex_row_center_between ${style.con}`}>
        {
          list.map((item, index) => {
            return (
              <div key={index} className={`flex_column_center_center ${style.box}`}>
                <div className={style.bg} style={item.style}></div>

                <div className={`flex_column_center_center ${style.box_con}`}>
                  <span className={`space_bottom ${style.box_title}`}>{ item.title }</span>
                  <span className={style.box_desc}>{ item.desc }</span>
                </div>
              </div>
            )
          })
        }
      </div>
      </Fade>
    </div >
  )
}

export default Advantage