import style from '../style/Alliance.module.css'
import Fade from 'react-reveal/Fade'

function Alliance( partner:string[] ) {
  return (
    <div className={`container flex_column_center ${style.content} ${partner && partner.length > 0 ? '' : 'display_none'}`}>
      <span className={`space_bottom ${style.title}`}>合作伙伴</span>

      <Fade bottom>
        <div className={`flex_row_center ${style.con}`}>
          {
            partner && partner.map((item, index) => {
              return (
                <div key={index} className={`${style.box}`}>
                  <img src={item} alt={item} className={style.img} /> 
                </div>
              )
            })
          }
        </div>
      </Fade>
    </div>
  )
}

export default Alliance