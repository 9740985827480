import Banner from '@/components/Banner'

import Alliance from './components/Alliance'
import Case from './components/Case'

import logo1 from '@/assets/images/componey/logo/albb.jpg'
import logo2 from '@/assets/images/componey/logo/bgy.jpg'
import logo3 from '@/assets/images/componey/logo/cpfs.jpg'
import logo4 from '@/assets/images/componey/logo/gsyjs.jpg'
import logo5 from '@/assets/images/componey/logo/hd.jpg'
import logo6 from '@/assets/images/componey/logo/hw.jpg'
import logo7 from '@/assets/images/componey/logo/hyjz.jpg'
import logo8 from '@/assets/images/componey/logo/IT.jpg'
import logo9 from '@/assets/images/componey/logo/jlyzs.jpg'
import logo10 from '@/assets/images/componey/logo/jyzs.jpg'
import logo11 from '@/assets/images/componey/logo/ldkg.jpg'
import logo12 from '@/assets/images/componey/logo/lh.jpg'
import logo13 from '@/assets/images/componey/logo/lybzs.jpg'
import logo14 from '@/assets/images/componey/logo/mzh.jpg'
import logo15 from '@/assets/images/componey/logo/rz.jpg'
import logo16 from '@/assets/images/componey/logo/tx.jpg'
import logo17 from '@/assets/images/componey/logo/wk.jpg'
import logo18 from '@/assets/images/componey/logo/yfzs.jpg'
import logo19 from '@/assets/images/componey/logo/zgjm.jpg'
import logo20 from '@/assets/images/componey/logo/zgjzsj.jpg'
import logo21 from '@/assets/images/componey/logo/zgznjj.jpg'
import logo22 from '@/assets/images/componey/logo/zhdc.jpg'
import logo23 from '@/assets/images/componey/logo/zx.jpg'
import logo24 from '@/assets/images/componey/logo/zy.jpg'


function Partner() {

  const banner = 'https://static.a-bene.com/abene-static/7d13bba366d960bf1d5e5eb1bbd4cd4c.png'
  const partnerData = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11,
    logo12,
    logo13,
    logo14,
    logo15,
    logo16,
    logo17,
    logo18,
    logo19,
    logo20,
    logo21,
    logo22,
    logo23,
    logo24,
  ]

  return (
    <>
      { Banner(banner) }
      <Case />
      { Alliance(partnerData) }
    </>
  )
}

export default Partner