import { Modal } from 'antd';
import style from './Customize.module.css'

interface CustomizeProps {
  visible:boolean
  handleCancel:Function
}

function Customize(props:CustomizeProps) {
  
  const { visible } = props

  const handleCancel = () => {
    props.handleCancel()
  }

  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        onCancel={handleCancel}
      >
        <div className={`content_space flex_column ${style.content}`}>
          <span className={`${style.componey}`}>深圳市安百纳智能实业有限公司 </span>
          <span className={style.desc}>地址：深圳市龙岗区甘李路2号中盛科技园6栋5层</span>
          <span className={`${style.desc}`}>合作热线：0755-82884431(周一至周日9:00-21:00)</span>

          <span className={`item_top ${style.componey}`}>韶关安百纳体验馆</span>
          <span className={style.desc}>地址：广东省韶关前进建材城C2座三楼16-17号</span>
          <span className={`${style.desc}`}>合作热线：0751-89133891(周一至周日9:00-21:00)</span>
        </div>
      </Modal>
    </>
  );
}

export default Customize