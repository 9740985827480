import Fade from 'react-reveal/Fade'
import style from '../style/Advantage.module.css'

import advantage01 from '@/assets/images/office/advantage01.png'
import advantage02 from '@/assets/images/office/advantage02.png'
import advantage03 from '@/assets/images/office/advantage03.png'
import advantage04 from '@/assets/images/office/advantage04.png'

const list = [
  {
    title: '智能高效管理',
    desc: '将办公室划分多种场景，通过触摸屏、无线控制灯等设备自动化运行，会议预约、微信签到、实时显示使用状况等，实现高效管理',
    style: { backgroundImage: `url(${advantage01})` }
  },
  {
    title: '安全防护管理',
    desc: '对办公室各区域、设备用电状态进行监控，通过用电数据分析，对异常用电推送消息提醒，实现24小时预警式电流保护和潜在火灾提醒应用。',
    style: { backgroundImage: `url(${advantage02})` }
  },
  {
    title: '降低能耗成本',
    desc: '对办公室内灯光、窗帘、空调等设备能耗进行精准监测，关掉多余能耗。并通过大数据分析持续提供节能优化策略方案。',
    style: { backgroundImage: `url(${advantage03})` }
  },
  {
    title: '先进性与一体化',
    desc: '会议室高清晰大屏幕覆盖、手机、电脑无线交互；兼容现有视频会议、搭建培训室直播、录播系统。',
    style: { backgroundImage: `url(${advantage04})` }
  },
]

function Advantage() {
  return (
    <div className={`container ${style.content}`}>
      <div className={`space_bottom content_space ${style.title}`}>系统优势</div>

      <Fade bottom>
        <div className={`flex_row_center_between content_max ${style.con}`}>
          {
            list.map((item, index) => {
              return (
                <div key={index} className={`flex_column_center_center ${style.box}`}>
                  <div className={style.bg} style={item.style}></div>

                  <div className={`flex_column_center_center ${style.box_con}`}>
                    <span className={`space_bottom ${style.box_title}`}>{ item.title }</span>
                    <span className={style.box_desc}>{ item.desc }</span>
                  </div>
                </div>
              )
            })
          }
        </div>
      </Fade>
    </div >
  )
}

export default Advantage