import axios from 'axios'
import { message } from 'antd'

const testUrl = 'http://172.16.12.241:11002'
const proUrl = 'https://api.a-bene.com'

const wangUrl = 'http://djese.com'
const wuUrl = 'http://172.16.12.79:11002'

axios.defaults.baseURL = proUrl
axios.defaults.timeout = 5000

// 响应拦截
axios.interceptors.response.use(
  /**
   * 下面的注释为通过在response里，自定义code来标示请求状态
   * 当code返回如下情况则说明权限有问题，登出并返回到登录页
   * 如想通过 XMLHttpRequest 来状态码标识 逻辑可写在下面error中
   * 以下代码均为样例，请结合自生需求加以修改，若不需要，则可删除
   */
  response => {
    const code = response && response.data.code
    if (code === 200) {
      // Message.closeAll()
      // Message({ message: response.data.msg, type: 'success', showClose: true })
      return response.data
    } else {
      handleError(code, response.data)
    }
  },
  error => {
    if (error.response) {
      const code = error.response.status
      handleError(code, error.response.data)
    } else {
      message.destroy()
      message.error('服务器异常')
      return false
    }
    return Promise.reject(error)
  }
)

/**
 * 处理错误
 * @param code 代码
 * @param data  数据
 */
function handleError (code:number, data:any) {
  message.destroy()

  switch (code) {
    case 1007:
      message.error('数据有误')
      break

    case 19001:
      message.error('验证码有误')
      break

    default:
      message.error('服务器错误')
      break
  }
}

export default axios