import Fade from 'react-reveal/Fade'
import style from '../style/Team.module.css'

export interface TeamProps {
  name:string
  image:string
  post:string
  content:string
}

function Team( teamData:TeamProps[] ) {

  return (
    <div className={`container content_max ${style.content} ${teamData.length > 0 ? '' : 'display_none'}`}>
      <Fade top>
      <span className={`space_bottom ${style.title}`}>领导团队</span>
      </Fade>
      <Fade bottom>
      <div className={`flex_row_center_around ${style.img_box}`}>
        {
          teamData && teamData.map((item, index) => {
            return (
              <div key={index} className={`flex_column ${style.box}`}>
                <img src={item.image} alt={item.name} className={style.img} />
                <div className={`flex_column ${style.introduce}`}>
                  <span className={style.name}>{ item.name }</span>
                  <span className={style.desc}>{ item.post }</span>
                  <span className={style.desc}>{ item.content }</span>
                </div>
              </div>
            )
          })
        }
      </div>
      </Fade>
    </div>
  )
}

export default Team