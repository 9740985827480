import Fade from 'react-reveal/Fade'
import style from '../style/EarlyWarning.module.css'

import earlyWarning01 from '@/assets/images/community/earlyWarning01.png'
import earlyWarning02 from '@/assets/images/community/earlyWarning02.png'
import earlyWarning03 from '@/assets/images/community/earlyWarning03.png'
import earlyWarning04 from '@/assets/images/community/earlyWarning04.png'

function EarlyWarning() {
  return (
    <>
      <div className={`container space_bottom content_space ${style.title}`}>智慧人行管理预警</div>

      <div className={`flex_column min_width ${style.bg}`}>
        <div className={`flex_row_start_between content ${style.box}`}>
          <Fade left>
          <div className={`flex_column content_space ${style.con}`}>
            <span className={style.box_title}>信息采集：</span>
            <span className={`space_bottom ${style.box_desc}`}>通过智能门禁系统对社区的出入口进行管理，居民可通过实名登记办理开门授权，社区完成实有人口和实有房屋信息登记和采集。</span>
            <span className={style.box_title}>数据采集：</span>
            <span className={`space_bottom ${style.box_desc}`}>系统配合智能门禁和人脸抓拍摄像头，对社区出入人员进行人脸、轨迹、行为、手机Mac地址等数据实时采集。</span>
            <span className={style.box_title}>人工管理：</span>
            <span className={`space_bottom ${style.box_desc}`}>一人一档，通过人口档案，可以看到以“人”为中心关联的房屋、车辆、家庭成员、访客，出入轨迹、抓拍记录等信息。系统配合智能终端的数据采集、分析与研判，对实有人口信息进行结构化分析与统计，实现了实有人口精细化管理，做到了底数清，情况明了。</span>
          </div>
          </Fade>
          <Fade right>
          <img src={earlyWarning01} alt={earlyWarning01} className={style.img} />
          </Fade>
        </div>

        <div className={`flex_row_center_between content ${style.row}`}>
          <Fade left>
          <img src={earlyWarning02} alt={earlyWarning02} className={style.img} />
          </Fade>
          <Fade top>
          <div className={`flex_column ${style.row_con}`}>
            <span className={`space_bottom ${style.box_title}`}>云门禁+广告门</span>
            <span className={style.box_desc}>固定用户：人脸识别/刷卡/蓝牙/APP/NFC/各种证卡</span>
            <span className={style.box_desc}>访客：业务远程APP/视频对讲</span>
            <span className={style.box_desc}>云门禁和广告门都可以作广告投放使用</span>
          </div>
          </Fade>
          <Fade right>
          <img src={earlyWarning03} alt={earlyWarning03} className={style.img} />
          </Fade>
        </div>

        <div className={`flex_row_center_around content ${style.row}`}>
          <Fade left>
          <div className={`flex_column ${style.row_con}`}>
            <span className={`space_bottom ${style.box_title}`}>云门禁+广告门</span>
            <span className={style.box_desc}>固定用户：人脸识别/刷卡/蓝牙/APP/NFC/各种证卡</span>
            <span className={style.box_desc}>访客：呼叫业主房号或者拨打业主手机号</span>
          </div>
          </Fade>
          <Fade bottom>
          <img src={earlyWarning04} alt={earlyWarning04} className={style.img} />
          </Fade>
          <Fade right>
          <div className={`flex_column ${style.row_con}`}>
            <span className={`space_bottom ${style.box_title}`}>人脸识别+通道闸</span>
            <span className={style.box_desc}>固定用户：人脸识别/刷卡/蓝牙/APP/NFC/各种证卡</span>
            <span className={style.box_desc}>访客：发卡/人脸登记/二代证登记/二维码通行</span>
            <span className={style.box_desc}>人脸识别终端和通道闸可根据现场情况选择不同产品</span>
          </div>
          </Fade>
        </div>
      </div>
    </ >
  )
}

export default EarlyWarning