import Fade from 'react-reveal/Fade'
import style from '../style/Introduce.module.css'

import video01 from '@/assets/images/video/video01.png'
import video02 from '@/assets/images/video/video02.png'
import video03 from '@/assets/images/video/video03.png'
import video04 from '@/assets/images/video/video04.png'
import video05 from '@/assets/images/video/video05.png'

function Advantage() {
  return (
    <div className={`container ${style.content}`}>
      <Fade top>
        <div className={`${style.introduce}`}>
          <div className={`space_bottom ${style.introduce_con}`}>即使在家中，也可以和家人好友共同体验只有在电影院里才能感受的震撼效果！在这个精心打造的家庭私人影院里，您可以亲历录音棚和电影院中的澎湃效果以及清晰明朗的对白和精确的声音定位。从柔和的耳语到惊心动魄的爆炸声，每个细节都犹如身临其境。</div>
          <div className={style.introduce_con}>家庭私人影院不同于家庭影院，除了对硬件器材上有要求外，还需要专业的声学、视觉设计装修，才能称为真正的家庭私人影院。目前市场主流为：3D立体私人影院 、THX标准私人影院 、 全景声私人影院 。而音箱外观又可分为：落地式、嵌入式、挂墙式、吸顶式、 隐藏式。</div>
        </div>
      </Fade>

      <div className={`flex_row_center max_width min_width ${style.box}`}>
        <div className={`flex_row_center ${style.row}`}>
          <Fade left>
            <img src={video01} alt={video01} className={style.img} />
          </Fade>

          <Fade right>
            <div className={`flex_column_start_center ${style.con}`}>
              <span className={style.con_title}>音视频系统</span>
              <span className={style.con_desc}>音视频系统由多声道音箱、AV功放、蓝光DVD、高清投影仪、幕布组成，每种设备都是需要互相衔接匹配，并结合房间尺寸、门窗数量及位置、个人观影习惯等因素综合考虑，才能设计出适合您自身需求同时又匹配房间特性的音视频系统。</span>
              <span className={style.con_desc}>ISF（Imaging Science Foundation）为影院视频调校的行业标准。采用专业的视频测试仪器对设备进行高精度的测量和调整，将色彩、色温与色调还原到导演所创作的好状态。</span>
            </div>
          </Fade>
        </div>

        <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>声学设计装修</span>
            <span className={style.con_desc}>好声音不仅取决于音响系统，视听环境也起着非常重要的作用。如果在一个不是很理想的环境中，再好的音响，声音都会受到限制，甚至表现很差！声学设计涉及到很多建筑声学的专业知识，不仅需要考虑材料外观，更要考虑房间的混响时间、驻波、声音的扩散等一系列声学问题。</span>
          </div>
        </Fade>

        <Fade right>
          <img src={video02} alt={video02} className={style.img} />
        </Fade>

        </div>
        <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <img src={video03} alt={video03} className={style.img} />
        </Fade>

        <Fade right>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>视觉设计装修</span>
            <span className={style.con_desc}>好的画面不仅取决配置的投影仪、幕布、高清线材，视觉环境也同样起着重要的作用。因为我们看到的画面是通过投影仪投射出的光线，再经过屏幕反射进入人眼，墙面、地面、顶面多个面的反射光也会反射到屏幕影响色彩和对比度，另外这些光源也会反射到人眼，每种反射光如果在设计时不加控制考虑，会出现高配置，低效果的尴尬局面。</span>
          </div>
        </Fade>

        </div>
        <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>影院智能控制</span>
            <span className={style.con_desc}>当您面对众多影音设备，如果对产品不是很了解，您会很难分辨每个设备所对应的遥控器，而每个遥控器上面至少有20个按钮，每个按钮基本都是英文，操作变得复杂而又尴尬。谁也不希望朋友来家聚会的时候，因为这些而影响观影效果。</span>
            <span className={style.con_desc}>而影院智能控制就让操作简单化，体验更直观。无论是灯光、功放、投影仪、幕布，视听室内任何电子产品都能实现一键式模块化控制，操作变得非常简单，所有尴尬将完全避免。</span>
          </div>
        </Fade>

        <Fade right>
          <img src={video04} alt={video04} className={style.img} />
        </Fade>

        </div>
        <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <img src={video05} alt={video05} className={style.img} />
        </Fade>

        <Fade right>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>影院沙发</span>
            <span className={style.con_desc}>当您面对众多影音设备，如果对产品不是很了解，您会很难分辨每个设备所对应的遥控器，而每个遥控器上面至少有20个按钮，每个按钮基本都是英文，操作变得复杂而又尴尬。谁也不希望朋友来家聚会的时候，因为这些而影响观影效果。</span>
            <span className={style.con_desc}>而影院智能控制就让操作简单化，体验更直观。无论是灯光、功放、投影仪、幕布，视听室内任何电子产品都能实现一键式模块化控制，操作变得非常简单，所有尴尬将完全避免。</span>
          </div>
        </Fade>
      </div>
      </div>
    </div>
  )
}

export default Advantage