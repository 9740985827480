import style from '../style/Specs.module.css'

function Precautions( data:string ) {
  return (
    <div id='Specs' className={`min_width ${style.box} ${data ? 'display_block' : 'display_none'}`}>
      <div className={style.content}>
        <div className={`space_bottom ${style.title}`}>产品参数</div>
        <div id='data' className={style.desc} dangerouslySetInnerHTML={{__html: data}} />
      </div>
    </div>
  )
}

export default Precautions