
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { routes, renderRoute } from './route';

import '@/styles/index.less'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {renderRoute(routes)}
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);