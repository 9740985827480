import { Image } from 'antd';
import Fade from 'react-reveal/Fade'

import style from '../style/Honor.module.css'
import '../style/antd.less'
import componeyBg from '@/assets/images/about/componeyBg.png'

const bg = { backgroundImage: `url(${componeyBg})` }
function Honor( honor:string[] ) { 

  return (
    <div className={`flex_row_center_center min_width max_width honor_content ${style.content} ${honor?.length > 0 ? '' : 'display_none'}`} style={bg}>
      <div className={`content_max flex_row_center_between`}>
        <Fade left>
          <div className={style.con}>累计已获<span className={style.green}>100多项</span>发明专利、实用新型专利、软件著作权及外观专利证书等</div>
        </Fade>
        <Fade right>
          <div className={`flex_column_center ${style.box}`}>
            <div className={`flex_row_center_center ${style.box_one}`}>
              <Image src={honor?.[1]} alt={style.image} className={style.img} />
              <Image src={honor?.[0]} alt={`${style.box_one} ${style.image}`} className={style.img} />
            </div>
            <div className={`flex_row_center_center ${style.box_two}`}>
              <Image src={honor?.[2]} alt={style.image} className={`${style.two_left} ${style.img}`} />
              <Image src={honor?.[3]} alt={style.image} className={`${style.two_center} ${style.img}`} />
              <Image src={honor?.[4]} alt={style.image} className={`${style.two_right} ${style.img}`} />
            </div>
            <div className={`flex_row_center_center ${style.box_three}`}>
              <Image src={honor?.[5]} alt={style.image} className={`${style.three_edge_left} ${style.img}`} />
              <Image src={honor?.[6]} alt={style.image} className={`${style.three_left} ${style.img}`} />
              <Image src={honor?.[7]} alt={style.image} className={`${style.three_center} ${style.img}`} />
              <Image src={honor?.[8]} alt={style.image} className={`${style.three_right} ${style.img}`} />
              <Image src={honor?.[9]} alt={style.image} className={`${style.three_edge_right} ${style.img}`} />
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default Honor