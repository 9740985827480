import Fade from 'react-reveal/Fade'
import style from '../style/Process.module.css'

import img from '@/assets/images/merchants/bg.png'
const bg = { backgroundImage: `url(${img})` }

const list = [ '信息咨询', '来司考察', '智慧体验', '投点调研', '设计方案', '签订合同', '施工安装', '技术支持', '培训指导', '售后服务' ]

function Process() {
  return (
    <div className={`flex_column_center min_width max_width`}>
      <span className={`space_bottom ${style.title}`}>加盟流程</span>

      <div className={`flex_column_center_center ${style.box}`} style={bg}>
        <span className={`flex_row_center_center ${style.box_title}`}>多重保障 无后顾之忧</span>
        <div className='flex_row_center'>
          <Fade top>
            {
              list.map((item, index) => {
                return (
                  <div key={index} className={`flex_column_center_around ${style.box_item}`}>
                    <span className={style.box_num}>{ index + 1 }</span>
                    <span className={style.box_desc}>{ item }</span>
                  </div>
                )
              })
            }
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Process