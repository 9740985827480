import { useState } from 'react'
import style from '../style/Fold.module.css'
import { EnvironmentFilled, ClockCircleFilled } from '@ant-design/icons'

export interface FoldInfo {
  name:string
  position:string
  date:string
  ability:string[]
}

function Fold( item:FoldInfo ) {
  const [show, setShow] = useState(false)

  // 点击查看详情
  const handleClickShow = () => {
    setShow(!show)
  }

  return (
    <div className={`flex_column ${style.box}`}>
      <div className={style.name}>{ item.name }</div>
      <div className={`flex_row_center ${style.sub}`}>
        <EnvironmentFilled className={style.icon} />{ item.position }
      </div>
      <div className={`flex_row_center ${style.sub} ${style.sub_bottom}`}>
        <ClockCircleFilled className={style.icon} />发布于{ item.date }
      </div>

      <div className={`flex_column ${style.ability} ${show ? '' : 'display_none'}`}>
        <span className={`${style.title}`}>任职资格：</span>
        {
          item.ability.map((itm, index) => {
            return <span className={style.desc} key={index}>{ itm }</span>
          })
        }
      </div>

      <div className={`flex_column ${style.ability} ${show ? '' : 'display_none'}`}>
        <span className={`${style.title}`}>福利待遇：</span>
        <span className={style.desc}>1、高弹性收入，高投入高回报；</span>
        <span className={style.desc}>2、工作时间：周一到周五/朝九晚六/8小时/天；</span>
        <span className={style.desc}>3、社会保障：入职五险一金，转正购买商业团体意外保险；</span>
        <span className={style.desc}>4、法定假日：国家法定节假日、法定年假；</span>
        <span className={style.desc}>5、团队建设：不定期组织大型活动，丰富员工文化活动，每年享受一次度假旅游机会；</span>        
        <span className={style.desc}>6、其他福利：</span>        
        <span className={`${style.desc} ${style.subDesc}`}>1）员工直系亲属去世慰问津贴（正常是200元，因每个地方风俗习惯而定）</span>        
        <span className={`${style.desc} ${style.subDesc}`}>2）员工结婚津贴300元</span>        
        <span className={`${style.desc} ${style.subDesc}`}>3）三八妇女节给全公司女同事放假半天，另享受100元红包</span>        
        <span className={`${style.desc} ${style.subDesc}`}>4）工作满三年以上员工，公司为该员工父母每月转账200元照顾津贴</span>        
      </div>

      <div className={`${style.contact} ${show ? '' : 'display_none'}`}>如果您想加入我们，请将您的简历发送至：zhongsx@a-bene.com，咨询电话:18926767685(钟小姐)</div>

      <div className={`flex_row_center_center ${style.bottom}`} onClick={() => {handleClickShow()}}>
        { show ? '收起' : '查看详情' }
      </div>
    </div>
  )
}

export default Fold