import Fade from 'react-reveal/Fade'
import style from '../style/Case.module.css'

import case01 from '@/assets/images/about/case1.png'
import case02 from '@/assets/images/about/case2.png'
import case03 from '@/assets/images/about/case3.png'
import case04 from '@/assets/images/about/case4.png'
import case05 from '@/assets/images/about/case5.png'
import case06 from '@/assets/images/about/case6.png'

const list = [
  { name: '东部华侨城天麓别墅', image: case01 },
  { name: '北京当代MOMA万国城', image: case02 },
  { name: '广州金茂府', image: case03 },
  { name: '北京当代MOMA万国城', image: case04 },
  { name: '广州金茂府', image: case05 },
  { name: '北京当代MOMA万国城', image: case06 }
]

function Case() {
  return (
    <div className='container flex_column_center'>
      <span className={style.title}>值得您信赖的合作案例</span>

      <Fade bottom>
        <div className={`flex_row_center_around ${style.con}`}>
          {
            list.map((item, index) => {
              return (
                <div key={index} className={`flex_column_center ${style.box}`}>
                  <img src={item.image} alt={item.image} className={style.img} />
                  <div className={`flex_row_center_center ${style.name}`}>{ item.name }</div>
                </div>
              )
            })
          }
        </div>
      </Fade>
    </div>
  )
}

export default Case