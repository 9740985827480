import Fade from 'react-reveal/Fade'
import Banner from '@/components/Banner'
import Fold from './components/Fold'

import style from './style/index.module.css'

import type { FoldInfo } from './components/Fold'
import React from 'react'

const list:FoldInfo[] = [
  {
    name: '销售',
    position: '深圳市    招聘6人    专科',
    date: '2021.10.21',
    ability: [
      '1、具备优秀的沟通与表达能力；',
      '2、职业目标清晰，热爱销售工作，勇于挑战困难，有强烈的成功欲望；',
      '3、市场营销或相关专业。',
    ]
  },
  {
    name: '产品经理',
    position: '深圳市    招聘1人    本科',
    date: '2020.06.01',
    ability: [
      '1、计算机及电子相关相关专业，专科及以上学历（经验丰富，学历可不作参考）；',
      '2、具有1年以上强弱电系统经验（主要是综合布线、通信设备安装/调试、计算机网络系统、安防系统等）；',
      '3、熟练掌握常用办公软件，OFFiceWord、EXcel 、AUTOcad 、PhotoShop等常用软件；',
      '4、沟通能力强、团队协作能力强、善于表达、学习；善于与人沟通，良好的团队合作精神和高度的责任感，能够承受压力，保证工作质量。'
    ] 
  }, 
  {
    name: '项目经理',
    position: '深圳市    招聘1人    本科',
    date: '2020.06.01',
    ability: [
      '1、计算机及电子相关相关专业，专科及以上学历（经验丰富，学历可不作参考）；',
      '2、具有1年以上强弱电系统经验（主要是综合布线、通信设备安装/调试、计算机网络系统、安防系统等）；',
      '3、熟练掌握常用办公软件，OFFiceWord、EXcel 、AUTOcad 、PhotoShop等常用软件；',
      '4、沟通能力强、团队协作能力强、善于表达、学习；善于与人沟通，良好的团队合作精神和高度的责任感，能够承受压力，保证工作质量。'
    ]
  }, 
  {
    name: '采购经理',
    position: '深圳市    招聘1人    本科',
    date: '2020.06.01',
    ability: [
      '1、计算机及电子相关相关专业，专科及以上学历（经验丰富，学历可不作参考）；',
      '2、具有1年以上强弱电系统经验（主要是综合布线、通信设备安装/调试、计算机网络系统、安防系统等）；',
      '3、熟练掌握常用办公软件，OFFiceWord、EXcel 、AUTOcad 、PhotoShop等常用软件；',
      '4、沟通能力强、团队协作能力强、善于表达、学习；善于与人沟通，良好的团队合作精神和高度的责任感，能够承受压力，保证工作质量。'
    ]
  }, 
  {
    name: '技术工程师',
    position: '深圳市    招聘1人    本科',
    date: '2020.06.01',
    ability: [
      '1、计算机及电子相关相关专业，专科及以上学历（经验丰富，学历可不作参考）；',
      '2、具有1年以上强弱电系统经验（主要是综合布线、通信设备安装/调试、计算机网络系统、安防系统等）；',
      '3、熟练掌握常用办公软件，OFFiceWord、EXcel 、AUTOcad 、PhotoShop等常用软件；',
      '4、沟通能力强、团队协作能力强、善于表达、学习；善于与人沟通，良好的团队合作精神和高度的责任感，能够承受压力，保证工作质量。'
    ]
  }
]

function Join() {
  
  const banner = 'https://static.a-bene.com/abene-static/7d13bba366d960bf1d5e5eb1bbd4cd4c.png'

  return (
    <>
      { Banner(banner) }
      <div className='container flex_column_center'>
        <Fade bottom>
          <span className={style.title}>春风十里，不如万物有你</span>
          <span className={`${style.desc}`}>在这里，我们希望看到人尽其才，让每个人的闪光点，照亮万物聚兴的美好。</span>
          <span className={`space_bottom ${style.desc}`}>万科物业“大树级事业”已在路上，期待与你同行。</span>
        </Fade>

        <Fade bottom>
          <div>
            {
              list.map( (item,index) => {
                return <React.Fragment key={index}>{ Fold(item) }</React.Fragment> 
              })
            } 
          </div>
        </Fade>
      </div>
    </>
  )
}

export default Join