import axios from '@/utils/request'

// 王总网站，因为两个域名，这个域名没备案？，该网站无法转发，所以固定网址
// 后续可以转发之后可以去掉
const wangUrl = 'https://djese.com'
/**
 * 获取产品
 * @param {Object} data 
 */
export function find_products(data:any) {
  return axios.get(`${wangUrl}/djesecom/product/products/page`, { params: data }) 
}

/**
 * 获取产品详情
 * @param {String} id 
 */
export function find_products_detail(id:string) {
  return axios.get(`${wangUrl}/djesecom/product/products/detail?id=${id}`) 
}

/**
 * 获取产品推荐
 * @param {Object} data 
 */
export function find_products_recommends(data:any) {
  return axios.get(`${wangUrl}/djesecom/product/recommends`, { params: data })
}

/**
 * 获取菜单
 * @param {Object} data 
 */
export function find_menus(data:any) {
  return axios.get(`${wangUrl}/djesecom/product/categorys/list`, { params: data })
}

/**
 * 获取菜单
 * @param {Object} data 
 */
export function find_menus_page(data:any) {
  return axios.get(`${wangUrl}/djesecom/product/categorys/page`, { params: data })
}
