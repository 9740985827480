import Fade from 'react-reveal/Fade'
import style from '../style/SystemWindow.module.css'

import window01 from '@/assets/images/family/window01.png'
import window02 from '@/assets/images/family/window02.png'
import window03 from '@/assets/images/family/window03.png'

const items = [
  {
    title: '自动联动窗户',
    subtitle: '刮风下雨，自动关窗',
    desc: '感应到刮风下雨的恶劣天气，门窗遮阳系统将自动关闭窗户，避免家中被雨水浸湿。',
    style: { backgroundImage: `url(${window01})` }
  }, {
    title: '百分比开合',
    subtitle: '开启清晨的一米阳光',
    desc: '无论是自定义的情景，或者通过MixPad的屏幕调节，窗帘开合的比例原来可以如此精细，避免家中被雨水浸湿。',
    style: { backgroundImage: `url(${window02})` }
  }, {
    title: '智能窗帘',
    subtitle: '远程遥控，一键启动',
    desc: '出门在外，担心家居被烈日暴晒？用安百纳APP一键远程开关窗帘，出门亦安心，避免家中被雨水浸湿。',
    style: { backgroundImage: `url(${window03})` }
  }
]

function SystemWindow() {
  return (
    <div id='window' className={`min_width max_width flex_row_center_between`}>
      {
        items.map(item => {
          return (
            <Fade top key={item.title}>
              <div className={`flex_column_center_center ${style.box}`}>
                <div className={style.bg} style={item.style}></div>
                <div className={`flex_column ${style.con}`}>
                  <span className={`space_bottom ${style.title}`}>{ item.title }</span>
                  <span className={`space_bottom ${style.subtitle}`}>{ item.subtitle }</span>
                  <span className={`${style.desc}`}>{ item.desc }</span>
                </div>
              </div>
            </Fade>
          )
        })
      }
    </div >
  )
}

export default SystemWindow