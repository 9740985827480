import Fade from 'react-reveal/Fade'
import style from '../style/Introduce.module.css'

import componey from '@/assets/images/about/componey.png'

function Introduce() {
  return (
    <div className={`flex_row_center_between max_width min_width item_top ${style.container}`}>
      <Fade left>
      <img src={componey} alt='公司图片' className={style.img} />
      </Fade>

      <Fade right>
      <div className={`flex_column_center_center ${style.content}`}>
        <span className={`space_bottom ${style.title}`}>关于安百纳</span>
        <span className={style.desc}>深圳市安百纳智能实业有限公司，专注于智慧家居及物联网综合解决方案，以物联网云及大数据平台为核心，集智能终端产品，提供全宅智慧家居解决方案，并应用于智慧办公、智慧公寓、智慧园区、智慧舞台、智慧酒店等行业。</span>

        <span className={`${style.desc} ${style.desc_space}`}>安百纳智能团队来自于IT、移动互联网、人工智能、物联网行业内多年的资深专家，通过潜心研发与技术实现，及全方位的智能化咨询、方案设计、产品与系统配置、风险与安全评估、工程实施与调试、技术支持的打磨与沉淀，我们将竭诚服务于用户、及物联网+智慧行业。</span>
      </div>
      </Fade>
    </div>
  )
}

export default Introduce