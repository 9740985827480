import style from '../style/Advantage.module.css'

import advantage01 from '@/assets/images/merchants/advantage01.png'
import advantage02 from '@/assets/images/merchants/advantage02.png'
import advantage03 from '@/assets/images/merchants/advantage03.png'
import advantage04 from '@/assets/images/merchants/advantage04.png'

const list = [
  {
    title: '匠心品质',
    desc: '国内专利及软著多项，拥有最完美的空间潮流智能产品体系',
    style: { backgroundImage: `url(${advantage01})` }
  },
  {
    title: '解决方案',
    desc: '产品迭代创新，保持前沿；满足不同空间智能产品需求；聚焦智慧家居解决方案；',
    style: { backgroundImage: `url(${advantage02})` }
  },
  {
    title: '区域渠道',
    desc: '多家线下合作媒体、渠道高频且立体式整合传播。聚焦设计端口，圈层设计群体',
    style: { backgroundImage: `url(${advantage03})` }
  },
  {
    title: '设计驱动',
    desc: '著名歌手黄艺明先生推荐品牌，城市精英设计交流沙龙/论坛',
    style: { backgroundImage: `url(${advantage04})` }
  }
]

function Advantage() {
  return (
    <div className='container flex_column_center content_max'>
      <span className={`space_bottom ${style.title}`}>创新优势</span>

        <div className={`flex_row_center_between content`}>
          {
            list.map((item, index) => {
              return (
                <div key={index} className={`flex_column ${style.box}`} style={item.style}>
                  <div className={style.box_con}>
                    <span className={`flex_row_center_center ${style.box_title}`}>{ item.title }</span>
                    <div className={style.line}></div>
                    <span className={style.desc}>{ item.desc }</span>
                  </div>
                </div>
              )
            })
          }
        </div>
    </div>
  )
}

export default Advantage