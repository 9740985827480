

import { useState, useEffect } from 'react'
import Fade from 'react-reveal/Fade'
import SystemWindow from './SystemWindow'
import SystemSecurity from './SystemSecurity'
import SystemVideo from './SystemVideo'
import SystemEnvironment from './SystemEnvironment'
import SystemLight from './SystemLight'

import style from '../style/System.module.css'

import system01 from '@/assets/images/family/system01.png'
import system02 from '@/assets/images/family/system02.png'
import system03 from '@/assets/images/family/system03.png'
import system04 from '@/assets/images/family/system04.png'
import system05 from '@/assets/images/family/system05.png'
import system06 from '@/assets/images/family/system06.png'
import systemCenter from '@/assets/images/family/systemCenter.png'

const items = [
  { icon: system01, key: 'video', title: '影音娱乐系统', desc: [ '背景音乐一体机', '智能语音机器人', '智能魔镜' ], style: {  top: '0', left: '60px' } },
  { icon: system02, key: 'security', title: '智能安防系列', desc: [ '智能门锁  智能云台摄像机', '门窗传感器  人体传感器' ,'烟雾传感器  燃气阀传感器' ,'温湿度传感器  智能可燃气体传感器' ,'智能水浸系统报警器  SOS紧急按钮'], style: {  top: '0', right: '60px' } },
  { icon: system03, key: 'environment', title: '暖通环境系统', desc: [ '空调面板' ,'地暖面板' ,'智能温控器' ,'VRV空调控制器' ], style: {  top: '280px', right: '0' } },
  { icon: system04, key: 'window', title: '能源管理系统', desc: [ '10A智能插座', '16A智能插座' ], style: {  bottom: '80px', right: '60px' } },
  { icon: system05, key: 'light', title: '智能照明系统', desc: [  '易系列多功能触控面板' ,'冰逸系列智能面板' ,'智享6键液晶面板' ,'智能幻彩灯带' ], style: {  bottom: '80px', left: '60px' } },
  { icon: system06, key: 'window', title: '门窗卷帘系统', desc: [ '超静音智能开合点击' ,'智能电动罗马帘' ,'智能卷帘电机' ,'智能晾衣架' ], style: {  top: '280px', left: '0' } }
]

function System() {
  const [current, setCurrent] = useState('')

  useEffect(() => {
    scrollToAnchor(current)
  }, [current])

  
  // 跳转到指定位置
  const scrollToAnchor = (anchorName:string) => {
    const anchorElement = document.getElementById('renderCon')
    switch (anchorName) {
      case 'window':
        handleScroll(anchorElement, 'end')
        break

      case 'video':
        handleScroll(anchorElement, 'start')
        break

      case 'security':
        handleScroll(anchorElement, 'start')
        break

      case 'environment':
        handleScroll(anchorElement, 'end')
        break

      case 'light':
        handleScroll(anchorElement, 'end')
        break

      default:
        break
    }
  }

  // 滑动位置
  const handleScroll = (anchorElement:HTMLElement | null, type:'end' | 'start') => {
    anchorElement && anchorElement.scrollIntoView({
      block: type === 'end' ? 'end' : 'start',
      behavior: 'smooth'
    })
  }

  // 渲染内容
  const renderCon = () => {
    switch (current) {
      case 'window':
        return <SystemWindow />

      case 'video':
        return <SystemVideo />

      case 'security':
        return <SystemSecurity />

      case 'environment':
        return <SystemEnvironment />

      case 'light':
        return <SystemLight />

      default:
        return <SystemWindow />
    }
  }


  // 点击内容修改显示内容
  const handleClickItem = (key:string) => {
    setCurrent(key)
  }

  return (
    <>
      <div id="system" className={`flex_column_center min_width max_width ${style.bg}`}>
        <Fade bottom>
          <span className={`title space_top item_bottom ${style.heading}`}>强大的全宅智能家居系统</span>
        </Fade>

        <div className={`content ${style.con}`}>
          <img src={systemCenter} alt='systemCenter' className={style.img_center} />
          {
            items.map((item, index) => {
              return (
                <div key={index} className={`flex_row ${style.icon_box}`} style={item.style} onClick={() => {handleClickItem(item.key)}}>
                  <div className={style.live}>
                    <img src={`${item.icon}`} alt={item.title} className={style.icon} />
                    <span></span>
                    <span></span>
                  </div>
                  <div className={`flex_column ${style.box}`}>
                    <span className={style.title}>{ item.title }</span>
                    {
                      item.desc.map((con, conIdx) => {
                        return <span key={conIdx} className={style.desc}>{ con }</span>
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>

      <div id="renderCon">
        { renderCon() }
      </div>
    </>
  )
}

export default System