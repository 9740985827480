/*
 * @Description: 布局
 * @Date: 2021-03-08 17:36:31
 * @Author: 黄治恩
 */

import React, { Component } from 'react';

import { renderRoute } from '@/route';
import type { RouteOption } from '@/route'

interface LayoutProps {
  routes: RouteOption[]
}

class Layout extends Component<LayoutProps> {

  // 路由切换回到顶部
  componentDidUpdate () {
    window.scrollTo({
      top: 0, 
      behavior: "smooth" 
    })
  }

  render() {
    const { routes } = this.props
    return (
      <>
        { renderRoute(routes) }
      </>
    );
  }
}

export default Layout;