

import Fade from 'react-reveal/Fade'

import style from '../style/SystemSecurity.module.css'

import security01 from '@/assets/images/family/security01.png'
import security02 from '@/assets/images/family/security02.png'
import security03 from '@/assets/images/family/security03.png'
import security04 from '@/assets/images/family/security04.png'

const items = [
  {
    desc: ['智能联动安防设备', '紧急报警，随时及时处理'],
    style: { backgroundImage: `url(${security01})` }
  }, {
    desc: ['远程看护老人孩子', '亲情关爱不缺席'],
    style: { backgroundImage: `url(${security02})` }
  }, {
    desc: ['无论何时何地', '家中情况，一手掌握'],
    style: { backgroundImage: `url(${security03})` }
  }
]

function SystemSecurity() {
  return (
    <div id='security' className={`flex_column min_width max_width`}>
      <div className={`flex_row_center_between`}>
        {
          items.map((item, index) => {
            return (
              <Fade top key={index}>
                <div className={`flex_column_center_center ${style.box}`}>
                  <div className={style.bg} style={item.style}></div>
                  <div className={`flex_column ${style.con}`}>
                    {
                      item.desc.map((con, conIdx) => {
                        return <span key={conIdx} className={`${style.desc}`}>{ con }</span>
                      })
                    }
                  </div>
                </div>
              </Fade>
            )
          })
        }
      </div >
      <Fade bottom>
        <img src={security04} alt='安防背景图' className={`min_width max_width ${style.img}`} />
      </Fade>
    </div >
  )
}

export default SystemSecurity