import Fade from 'react-reveal/Fade'
import Banner from '@/components/Banner'
import Introduce from './components/Introduce'

import style from './style/index.module.css'

import bg01 from '@/assets/images/hotel/bg01.png'
import bg02 from '@/assets/images/hotel/bg02.png'
import bg03 from '@/assets/images/hotel/bg03.png'

function Hotel() {

  const banner = 'https://static.a-bene.com/abene-static/f7b53a69cd61be7b51d3efe79568cb10.png'

  const renderBannerCon = () => {
    return (
      <div className={`flex_column_center ${style.banner_con}`}>
        <span className={`space_bottom ${style.banner_title}`}>智慧酒店</span>
        <span className={style.banner_desc}>智慧酒店解决方案是让酒店拥有一套完善的智能化体系，通过互联网技术、通信技术和云服务技术，融合传统酒店智能化的各个子系统，实现酒店的智能化功能，为消费者提供更科技化更人性化的用户体验及服务。</span>
      </div>
    )
  }

  return (
    <div className='flex_column_center_center'>
      { Banner(banner, renderBannerCon()) }
      <Fade bottom>
        <img src={bg01} alt='背景图01' className={`min_width max_width ${style.bg} ${style.bg1}`} />
      </Fade>
      <Fade top>
        <img src={bg02} alt='背景图02' className={`min_width max_width ${style.bg} ${style.bg2}`} />
      </Fade>
      <Fade bottom>
        <img src={bg03} alt='背景图03' className={`min_width max_width ${style.bg} ${style.bg3}`} />
      </Fade>
      <Introduce />
    </div >
  )
}

export default Hotel