import Fade from 'react-reveal/Fade'
import style from '../style/Analysis.module.css'

import bg01 from '@/assets/images/park/bg01.png'
import bg02 from '@/assets/images/park/bg02.png'
import bg03 from '@/assets/images/park/bg03.png'
import bg04 from '@/assets/images/park/bg04.png'
import bg05 from '@/assets/images/park/bg05.png'
import bg06 from '@/assets/images/park/bg06.png'

const list = [
  {
    title: '管理粗放',
    desc: '无法统一调度，各种耗材管理粗放，无法有效追溯',
    style: { backgroundImage: `url(${bg01})` }
  },
  {
    title: '依靠经验',
    desc: '园区管理人员依靠经验管理',
    style: { backgroundImage: `url(${bg02})` }
  },
  {
    title: '被动维护',
    desc: '所有问题处置处于被动处置',
    style: { backgroundImage: `url(${bg03})` }
  },
  {
    title: '管理无序',
    desc: '园区运营管理工作范围广、管理不规范',
    style: { backgroundImage: `url(${bg04})` }
  },
  {
    title: '突发处置',
    desc: '每次的处置都是突发情况，没有计划性和预防性',
    style: { backgroundImage: `url(${bg05})` }
  },
  {
    title: '效率低下',
    desc: '缺乏有效规范的流程和制度，造成人员工作效率低',
    style: { backgroundImage: `url(${bg06})` }
  }
]

function Analysis() {
  return (
    <div className={`container flex_column content_space ${style.content}`}>
      <span className={`space_bottom ${style.title}`}>市场分析</span>

      <Fade top>
      <div className={`${style.con}`}>
        {
          list.map((item, index) => {
            return (
              <div key={index} className={`flex_column_center ${style.box}`}>
                <div className={`flex_column_center_center ${style.bg_box}`}>
                  <div className={`flex_column_center_center ${style.bg}`} style={item.style}>
                  </div>
                  <span className={style.bg_title}>{ item.title }</span>
                </div>
                <span className={`flex_row_center ${style.desc}`}>{ item.desc }</span>
              </div>
            )
          })
        }
      </div>
      </Fade>
    </div >
  )
}

export default Analysis