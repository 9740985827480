import { useState, useEffect, } from 'react'
import Fade from 'react-reveal/Fade'
import { Pagination, Image } from 'antd'

import NotData from '@/components/NotData'
import Banner from '@/components/Banner'

import { find_news } from '@/api/website'
import { setLocalData } from '@/utils/storage'
import { formatDate } from '@/utils/utils'
import { NEWS_INFO } from './constants'

import style from './style/index.module.css'
import './style/antd.less'
import morentupian from '@/assets/images/common/morentupian.svg'

export interface NewsProps {
  current: string
  records: NewsItem[]
  first: boolean
  last: boolean
  total: string
}

export interface NewsItem {
  id: string
  title: string
  subtitle?: string
  pic?: string
  lastModifiedAt: Date
}

function News( ) {

  const [newsInfo, setNewsInfo] = useState({} as NewsProps)
  const [current, setCurrent] = useState(1)
  const [top, setTop] = useState([] as NewsItem[])
  const [lists, setLists] = useState([] as NewsItem[])

  const banner = 'https://static.a-bene.com/abene-static/7d13bba366d960bf1d5e5eb1bbd4cd4c.png'


  // 设置数据
  const setNews = (data:NewsProps) => {
    setNewsInfo(data)
    const topData =  data.records?.slice?.(0, 1) ?? []
    const listsData = data.records?.slice?.(1, data.records.length) ?? []
    setTop(topData)
    setLists(listsData)
  }

  useEffect(() => {
    // 获取news数据
    const loadNews = ()=> {
      const params = {
        application: 'Website',
        pageNumber: 1,
        pageSize: 7,
        platform: 'PC',
        type: 'Notice'
      }
      find_news(params).then((response:any) => {
        if (response?.code === 200) {
          const { data }:{data:NewsProps} = response 
          setNews(data)
        }
      });
    }
    loadNews()
  }, [])
  
  // 点击分页
  const handleChangePage = (pageNumber:number) => {
    let anchorElement = document.querySelector('#news')
    // 如果对应id的锚点存在，就跳转到锚点
    if(anchorElement) { 
      anchorElement.scrollIntoView({
        behavior: 'smooth'
      })
    }

    const params = {
      application: 'Website',
      pageNumber: pageNumber,
      pageSize: 7,
      platform: 'PC',
      type: 'Notice'
    }
    
    find_news(params).then((response:any) => {
      if (response?.code === 200) {
        const { data }:{data:NewsProps} = response 
        setNews(data)
        setCurrent(pageNumber)
      }
    })
  }

  // 详情
  const handleOnClick = (id:string) => {
    setLocalData(NEWS_INFO, newsInfo)
    window.open(`/about/news/${id}`)
  }

  return (
    <>
      { Banner(banner) }
      <div id="news" className={style.newsInfo} >
        <div className={`container content_space `}>
          <Fade bottom>
            <div>
              {
                top.length > 0 && top.map((item, index) => {
                  return (
                    <div onClick={() => {handleOnClick(item.id)}} key={index} className={`flex_row_center top_content ${style.top}`}>
                      <Image 
                        src={item.pic} 
                        fallback={morentupian} 
                        alt={item.title} 
                        preview={false}
                        className={`${style.top_img}`} 
                        width={'50%'}
                        height={400} 
                      />
                      <div className={`flex_column_center_center ${style.top_con}`}>
                        <span className={style.top_title}>{ item.title }</span>
                        <span className={`space_bottom ${style.top_date}`}>{ formatDate(item.lastModifiedAt, 'YYYY-MM-DD') }</span> 
                        <span className={style.top_subtitle}>{ item.subtitle }</span>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </Fade>

          <Fade bottom>
            <div className={`${style.con}`}>
              {
                lists && lists.map((item, index) => {
                  return (
                    <div onClick={() => {handleOnClick(item.id)}} key={index} className={`flex_column ${style.box}`}>
                      <Image 
                        src={item.pic} 
                        fallback={morentupian} 
                        preview={false}
                        alt={item.title} 
                        className={style.img} 
                      />
                      <div className={`flex_column_start_between ${style.box_con}`}>
                        <span className={style.title}>{ item.title }</span>
                        <span className={style.date}>{ formatDate(item.lastModifiedAt, 'YYYY-MM-DD') }</span>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </Fade>

          <div className='flex_row_start_end space_bottom'>
            <Pagination 
              current={current} 
              total={Number(newsInfo?.total ?? 0)} 
              defaultPageSize={7} 
              pageSizeOptions={[String(7)]} 
              pageSize={7} 
              showSizeChanger={false} 
              onChange={handleChangePage} 
            />
          </div>

          { newsInfo?.records?.length === 0 && <NotData /> }
        </div>
      </div>

    </>
  )
}

export default News