import dayjs from 'dayjs'
/***
 * @description 格式化日期
 * @param time
 */
 export const formatDate = (
    time:Date | number, 
    format:string = 'YYYY-MM-DD HH:mm:ss'
  ) => dayjs(time).format(format)

/**
 * @desc 获取给定变量的原始类型字符串，[[Class]]: [object Object] => Object
 * @param {*} value - 给定变量
 * @return {String} 返回给定变量的原始类型的小写形式，e.g. getRawType(new Date) return 'date'
 */
 export const getRawType = (value:any) => {
  return toString.call(value).slice(8, -1).toLowerCase()
}

/**
 * @description 校验某个值是否为空
 * @param {*} val
 * @returns {boolean} 返回该值是否为空
 */
export const isEmpty = (val:any) => {
  // null, undefined
  if (val == null) return true

  if (typeof val === 'boolean') return false

  // 0 不视为空
  if (typeof val === 'number') return false

  // Error 对象的 `message` 属性是否为空
  // see: https://developer.mozilla.org/zh-CN/docs/Web/JavaScript/Reference/Global_Objects/TypeError
  if (val instanceof Error) return val.message === ''
  switch (getRawType(val)) {
    // String, Array
    case 'string':
    case 'array':
    return !val.length

    // Set, Map, File
    case 'set':
    case 'map':
    case 'file':
    return !val.size

    // 纯对象
    case 'object':
    return !Object.keys(val).length
  }

  return false
}