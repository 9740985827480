/*
 * @Description: 产品详情
 * @Date: 2021-09-27 16:12:56
 * @Author: 黄治恩
 */

import { useState, useEffect } from 'react'
import { Spin } from 'antd'
import { withRouter } from 'react-router-dom'
import NotData from '@/components/NotData'
import Fade from 'react-reveal/Fade'
import Description from './components/Description'
import Specs from './components/Specs'
import Precautions from './components/Precautions'

import style from './style/index.module.css'
import { find_products_detail } from '@/api/product'

interface InfoProps {
  id:string
  MingCHen:string
  TuPian:string
  MiaoSHu:string
  CanSHu:string
  GongNeng:string
}

function ProductDetails({match}:any) {
  const [isLoading, setLoading] = useState(false)
  const [info, setInfo] = useState({} as InfoProps)
  const [length, setLength] = useState(0)

  useEffect(() => {
    const { id } = match?.params
    if (id) {
      setLoading(true)
      find_products_detail(id).then((response:any) => {
        if (response?.code === 200) {
          const data = response.list as InfoProps
          document.title = data.MingCHen
          setLength(data?.MiaoSHu?.length || data?.GongNeng?.length || data?.CanSHu?.length ? 1 : 0)
          setInfo(data)
        }
      }).finally(() => {
        setLoading(false)
      })
    }
  }, [match])

  // 跳转到指定位置
  const scrollToAnchor = (anchorName:string) => {
    if (anchorName) {
        // 找到锚点
        let anchorElement = document.getElementById(anchorName)
        // 如果对应id的锚点存在，就跳转到锚点
        if(anchorElement) { 
          anchorElement.scrollIntoView({
            behavior: 'smooth'
          })
        }
    }
  }

  return (
    <Spin spinning={isLoading}>
      <Fade top>
        <div className={`flex_row_center min_width ${style.nav}`}>
          <div className={`flex_row_center_between ${style.content}`}>
            <span className={style.name}>{ info.MingCHen }</span>
            <div className='flex_row_center'>
              <span className={`${style.menu} ${info.CanSHu ? 'display_block' : 'display_none'}`} onClick={() => {scrollToAnchor('Specs')}}>产品参数</span>
              <span className={`${style.menu} ${info.GongNeng ? 'display_block' : 'display_none'}`} onClick={() => {scrollToAnchor('Precautions')}}>功能</span>
            </div>
          </div>
        </div>
      </Fade>

      <Fade bottom>
        { Description(info.MiaoSHu, info.TuPian) }
      </Fade>
      
      <Fade bottom>
        { Precautions(info.GongNeng) }
      </Fade>

      <Fade top>
        { Specs(info.CanSHu) }
      </Fade>

      { length === 0 
        ? <Fade top>
            <div className='min_width flex_column_center'>
              <NotData />
            </div>
          </Fade> 
        : ''
      }
      
    </Spin>
  )
}

export default withRouter(ProductDetails)