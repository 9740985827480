import { useState, useEffect } from 'react'
import style from '../style/List.module.css'
import {find_products} from '@/api/product'
import NotData from '@/components/NotData'
import { Button, Pagination } from 'antd'

export interface Product {
  data:any[]
  total:number
}

function List( product:Product, id:string ) {
  const [list, setList] = useState(product.data || [])
  const [total, setTotal] = useState(product.total || 0)
  const [current, setCurrent] = useState(1)
  const size = 10

  useEffect(() => {
    setList(product.data || [])
    setTotal(product.total || 0)
    setCurrent(1)
  }, [product])

  // 点击产品
  const handleOnClickRroduct = (route:string) => {
    window.open(route)
  }

  // 点击分页
  const handleChangePage = (page:number) => {
    let anchorElement = document.getElementById('menus')
    // 如果对应id的锚点存在，就跳转到锚点
    if(anchorElement) { 
      anchorElement.scrollIntoView({
        behavior: 'smooth'
      })
    }

    const param = {
      pageNumber: page,
      id: id ,
      pageSize:size
    }

    find_products(param).then((response:any) => {
      if (response?.code === 200) {
        const { list = [], total = 0 } = response
        setCurrent(page)
        setList(list)
        setTotal(parseInt(total))
      }
    })
  }
  
  return (
    <div className={`container`}>
      <div id="menus" className={`content ${style.content}`}>
        {
          list.length > 0 && list.map((item, index) => {
            return (
              <div onClick={() => { handleOnClickRroduct(`/product/${item.id}`) }} className={`${style.box}`} key={index}>
                <div className={style.img_box}>
                  <img src={item.TuPian} alt={item.TuPian} className={style.img} />
                </div>
                <div className={`flex_column_start_between ${style.con}`}>
                  <div className={`flex_column`}>
                    <span className={style.name}>{ item.MingCHen }</span>
                    <span className={style.brief}>{ item.XingHao }</span>
                    <span className={style.brief}>{ item.BeiZHu }</span>
                  </div>
                  <Button className={style.btn} type="primary" shape="round">查看详情</Button>
                </div>
              </div>
            )
          })
        }

        <div className='flex_row_start_end' style={{width: '100%'}}>
          <Pagination 
            current={current} 
            total={total} 
            pageSize={size} 
            showSizeChanger={false} 
            hideOnSinglePage 
            onChange={handleChangePage} 
          />
        </div>

        { list.length === 0 ? <NotData /> : '' }
      </div>
    </div>
  )
}

export default List