import style from '../style/Precautions.module.css'

function Precautions(data:string){
  return (
    <div id='Precautions' className={`min_width ${style.box} ${data ? 'display_block' : 'display_none'}`}>
      <div className={style.content}>
        <div className={`space_bottom ${style.title}`}>产品功能</div>
        <div id='data' className={style.desc} dangerouslySetInnerHTML={{__html: data}} /> 
      </div>
    </div>
  )
}

export default Precautions