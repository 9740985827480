import System from './components/System'
import Day from './components/Day'
import Case from './components/Case'

import Banner from '@/components/Banner'
import style from './style/index.module.css'

function Family() {
  
  const banner = 'https://static.a-bene.com/abene-static/a858f52a96c6ac0ea5b0bfb70bf70a60.png'

  const renderBannerCon = () => {
    return (
      <div className={`flex_column_center ${style.banner_con}`}>
        <span className={`space_bottom ${style.banner_title}`}>智慧家庭</span>
        <span className={style.banner_desc}>运用智能手机、平板电脑、智能面板等终端对家居设备进行管理和控制，或者是通过设备的数据共享和联动，以及传感器的信号输入作为触发条件，实现自动控制的技术和系统，为用户提供更加安全、舒适、便捷的智慧生活解决方案。</span>
      </div>
    )
  }

  return (
    <div className='flex_column_center_center'>
      { Banner(banner, renderBannerCon()) }
      <System />
      <Day />
      <Case />
    </div >
  )
}

export default Family