import Banner from '@/components/Banner'
import Introduce from './components/Introduce'

import style from './style/index.module.css'

function Video() {
  
  const banner = 'https://static.a-bene.com/abene-static/f9eefee76c10c376872694a5967c2700.png'

  const renderBannerCon = () => {
    return (
      <div className={`flex_column_center ${style.banner_con}`}>
        <span className={`space_bottom ${style.banner_title}`}>智慧影音</span>
        <span className={style.banner_desc}>借助物联网、传感网、互联网等网络通信技术，以满足社区居民和社区管理为导向，整合社区各要素资源，完善社区智能化、信息化的构造，真正实现社区智慧化、生活智能化。</span>
      </div>
    )
  }

  return (
    <>
      { Banner(banner, renderBannerCon()) }
      <Introduce />
    </ >
  )
}

export default Video