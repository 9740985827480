import Fade from 'react-reveal/Fade'
import style from '../style/Support.module.css'

import support01 from '@/assets/images/merchants/support01.png'
import support02 from '@/assets/images/merchants/support02.png'
import support03 from '@/assets/images/merchants/support03.png'
import support04 from '@/assets/images/merchants/support04.png'

function Support() {
  return (
    <div className='flex_column_center container'>
      <Fade top>
        <span className={`space_bottom ${style.title}`}>总部支持</span>
      </Fade>

      <div className={`flex_row_center ${style.box}`}>
        <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <img src={support01} alt={support01} className={style.img} />
        </Fade>

        <Fade right>
          <div className={`flex_row_center_center ${style.con_width}`}>
            <div className={`flex_column_center_center ${style.con}`}>
              <span className={style.con_title}>店面设计标准化模板</span>
              <span className={style.con_desc}>打造具有高辨识度的ABN设计符号的终端展示与消费场景体验。标准化的设计流程，店面建设项目负责制，严格的施工监理管控制度。</span>
            </div>
          </div>
        </Fade>
        </div>
        
        <div className={`flex_row_center ${style.row}`}>
        <Fade left>
        <div className={`flex_row_center_center ${style.con_width}`}>
          <div className={`flex_column_center_center ${style.con}`}>
            <span className={style.con_title}>差异化的设计营销服务</span>
            <span className={style.con_desc}>提供一整套终端设计营销管理工具，包括产品要义、案例、解决方案、终端管理手册、营销物料等，让终端的设计营销服务流程化，系统化、标准化。</span>
          </div>
        </div>
        </Fade>

        <Fade right>
          <img src={support02} alt={support02} className={style.img} />
        </Fade>
        </div>
        
        <div className={`flex_row_center ${style.row}`}>
        <Fade left>
          <img src={support03} alt={support03} className={style.img} />
        </Fade>

        <Fade right>
          <div className={`flex_row_center_center ${style.con_width}`}>
            <div className={`flex_column_center_center ${style.con}`}>
              <span className={style.con_title}>定期课程培训、全程通关</span>
              <span className={style.con_desc}>一次成交，打造成交利器。线上与线下培训结合，专业讲师现场授课，全程跟进辅导，手把手指导，确保每一位学员学习通关。</span>
            </div>
          </div>
        </Fade>
        </div>

        <div className={`flex_row_center ${style.row}`}>
        <Fade left>
        <div className={`flex_row_center_center ${style.con_width}`}>
          <div className={`flex_column_start_center ${style.con}`}>
            <span className={style.con_title}>品牌全域渠道</span>
            <span className={style.con_desc}>A，推广活动：精耕设计师渠道、为终端赋能。渗透当地市场，提高区域品牌认知度。</span>
            <span className={style.con_desc}>B，营销宣传：多渠道媒体、广告营销，助力区域品牌造势、提升曝光率。</span>
            <span className={style.con_desc}>C，案例传播：与知名杂志战略合作，推广高品质项目案例，影响群体关注，展现品牌实力。</span>
          </div>
        </div>
        </Fade>

        <Fade right>
          <img src={support04} alt={support04} className={style.img} />
        </Fade>
      </div>
      </div>
    </div>
  )
}

export default Support