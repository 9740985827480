import Banner from '@/components/Banner'
import Advantage from './components/Advantage'
import Introduce from './components/Introduce'

import style from './style/index.module.css'

function Office() {
  
  const banner = 'https://static.a-bene.com/abene-static/a1eefa6ad64950033fd3cd0298b60a1e.png'

  const renderBannerCon = () => {
    return (
      <div className={`flex_column_center ${style.banner_con}`}>
        <span className={`space_bottom ${style.banner_title}`}>智慧办公</span>
        <span className={style.banner_desc}>系统可以自动调节室内照明、窗帘、温度等，保持舒适的办公环境，还可以设置多种场景模式，如：会议模式、休息模式、工作模式等，让办公人员更好地专注于工作。从而打造舒适高效、节能低耗、安全智能的智慧办公场所。不仅仅提高工作人员的管理效率，而且可以让办公人员感到便利与舒适。</span>
      </div>
    )
  }

  return (
    <>
      {Banner(banner, renderBannerCon())}
      <Advantage />
      <Introduce />
    </>
  )
}

export default Office