import Fade from 'react-reveal/Fade'
import style from './Banner.module.css'

function Banner( banner:string, renderBannerCon?:any ){
  return (
    <>
      <Fade top>
        <div className={`flex_column_center_center banner ${style.box}`}>
          <img src={banner} alt="banner" className='banner' />
          <div className={style.con}>
            { renderBannerCon }
          </div>
        </div>
      </Fade>
    </>
  )
}

export default Banner