import style from '../style/Background.module.css'
import Fade from 'react-reveal/Fade'

import bg from '@/assets/images/park/bg.png'

function Background() {
  return (
    <div className={`container flex_column content_space ${style.content}`}>
      <span className={`space_bottom ${style.title}`}>行业背景</span>

      <div className='flex_row_center'>
        <Fade left>
        <img src={bg} alt='行业背景' className={style.img} />
        </Fade>
        <Fade right>
        <div className={`flex_column ${style.con}`}>
          <span className={style.desc}>以产业聚焦为手段的园区经济发展迅速</span>
          <span className={style.desc}>让园区招商引资带来新的突破口</span>
          <span className={style.desc}>各地园区经济呈现出覆盖区域不断扩大</span>
          <span className={style.desc}>产值越来越集中，GDP占比越来越大状态。</span>
        </div>
        </Fade>
      </div>
    </div >
  )
}

export default Background