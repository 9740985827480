import Fade from 'react-reveal/Fade'
import Banner from '@/components/Banner'
import Advantage from './components/Advantage'
import Support from './components/Support'
import Process from './components/Process'

function Merchants() {
 
  const banner = 'https://static.a-bene.com/abene-static/56bb169662b3ef5994e51ccd2d3a0e25.png'

  return (
    <>
      { Banner(banner) }
      <Advantage />
      <Support />
      <Fade top>
        <Process />
      </Fade>
    </>
  )
}

export default Merchants