import Banner from '@/components/Banner'
import Advantage from './components/Advantage'
import Introduce from './components/Introduce'
import EarlyWarning from './components/EarlyWarning'
import Security from './components/Security'

import style from './style/index.module.css'

function Community() {
 
  const banner = 'https://static.a-bene.com/abene-static/7f65d059e64bfe083beee5376bc226f1.png'

  const renderBannerCon = () => {
    return (
      <div className={`flex_column_center ${style.banner_con}`}>
        <span className={`space_bottom ${style.banner_title}`}>智慧社区</span>
        <span className={style.banner_desc}>借助物联网、传感网、互联网等网络通信技术，以满足社区居民和社区管理为导向，整合社区各要素资源，完善社区智能化、信息化的构造，真正实现社区智慧化、生活智能化。</span>
      </div>
    )
  }

  return (
    <>
      { Banner(banner, renderBannerCon())}
      <Advantage />
      <Introduce />
      <EarlyWarning />
      <Security />
    </>
  )
}

export default Community