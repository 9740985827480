import Fade from 'react-reveal/Fade'

import style from '../style/SystemLight.module.css'

import light01 from '@/assets/images/family/light01.png'
import light02 from '@/assets/images/family/light02.png'
import light03 from '@/assets/images/family/light03.png'

function SystemLight() {
  return (
    <div id='light' className={`min_width max_width ${style.bg}`}>
      <div className={`content_max flex_row_center_between item_bottom`}>
        <Fade left>
          <img src={light01} alt='智能调光' className={style.img} />
        </Fade>
        <Fade top>
          <div className={`flex_column ${style.con}`}>
            <span className={`space_bottom ${style.title}`}>智能调光</span>
            <span className={`space_bottom ${style.subtitle}`}>光影艺术，从未设限</span>
            <span className={style.desc}>1000级精密调光，通过滑动MixPad屏幕的调光控制条，实现各种场景氛围所需的光照强度。</span>
          </div>
        </Fade>
        <Fade right>
          <img src={light02} alt='智能调色' className={style.img} />
        </Fade>
      </div>
      <div className={`content_max flex_row_center_between item_bottom`}>
        <Fade left>
        <div className={`flex_column ${style.con}`}>
          <span className={`space_bottom ${style.title}`}>智能调色</span>
          <span className={`space_bottom ${style.subtitle}`}>家庭氛围的魔法师</span>
          <span className={style.desc}>1600万种颜色自由调节，浪漫的浅粉、恬静的淡绿,用不同颜色的光影营造不同的生活氛围。</span>
        </div>
        </Fade>
        <Fade bottom>
          <img src={light03} alt='智能开关' className={style.img} />
        </Fade>
        <Fade right>
          <div className={`flex_column ${style.con}`}>
            <span className={`space_bottom ${style.title}`}>智能开关</span>
            <span className={`space_bottom ${style.subtitle}`}>随时随地，随心所欲</span>
            <span className={style.desc}>支持手机APP、MixPad语音控制等方式控制家中灯光，无论出门还是在家，随时随地可以随心所欲。</span>
          </div>
        </Fade>
      </div>
    </div >
  )
}

export default SystemLight