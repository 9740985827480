
import Fade from 'react-reveal/Fade'
import style from '../style/Day.module.css'

import img from '@/assets/images/family/day.png'

function Day() {
  return (
    <div className={`min_width max_width flex_column_center ${style.bg}`}>
      <Fade bottom>
        <span className={`title item_top ${style.heading}`}>24小时给您无微不至的呵护</span>
      </Fade>
      <Fade bottom>
        <img src={img} alt='bg' className={`min_width content_max ${style.img}`} />
      </Fade>
    </div >
  )
}

export default Day