import Banner from '@/components/Banner'
import Background from './components/Background'
import Analysis from './components/Analysis'
import Business from './components/Business'

import style from './style/index.module.css'

function Park() {
  
  const banner = 'https://static.a-bene.com/abene-static/b6c60e585000b0030d9231ebb9b05b93.png'

  const renderBannerCon = () => {
    return (
      <div className={`flex_column_center ${style.banner_con}`}>
        <span className={`space_bottom ${style.banner_title}`}>智慧园区</span>
        <span className={style.banner_desc}>融合新一代信息与通信技术，具备迅捷信息采集、高速信息传输、高度集中计算、智能事务处理和无所不在的服务提供能力，实现园区内及时、互动、整合的信息感知、传递和处理，以提高园区产业集聚能力、企业经济竞争力、园区可持续发展为目标的先进园区发展理念。</span>
      </div>
    )
  }

  return (
    <>
      { Banner(banner, renderBannerCon()) }
      <Background />
      <Analysis />
      <Business />
    </ >
  )
}

export default Park