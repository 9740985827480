
import { useState } from 'react'
import Fade from 'react-reveal/Fade'
import Customize from '@/components/Customize'
import style from '../style/Case.module.css'

import case01 from '@/assets/images/family/case01.png'
import case02 from '@/assets/images/family/case02.png'
import case03 from '@/assets/images/family/case03.png'
import case04 from '@/assets/images/family/case04.png' 

function Case() {
  const [visible, setVisible] = useState(false)

  // 点击定制
  const handleClickCase = () => {
    setVisible(true)
  }

  // 点击关闭制定
  const handleCancelCustomize = () => {
    setVisible(false)
  }

  return (
    <>
      <div className={`flex_column_center_center min_width max_width ${style.bg}`}>
        <Fade top>
          <span className={`title ${style.title}`}>标准化或智慧化   生活其实并不贵</span>
        </Fade>

        <div className={`flex_row_center_around content_max item_bottom`}>
          <Fade bottom>
            <div className={`flex_column_center ${style.box}`}>
            <img src={case01} alt='案例图1' className={style.img} />
              <div className={`${style.btn}`} onClick={handleClickCase}>立即定制</div>
            </div>
          </Fade>
          <Fade bottom>
            <div className={`flex_column_center ${style.box}`}>
            <img src={case02} alt='案例图2' className={style.img} />
              <div className={`${style.btn}`} onClick={handleClickCase}>立即定制</div>
            </div>
          </Fade>
          <Fade bottom>
            <div className={`flex_column_center ${style.box}`}>
            <img src={case03} alt='案例图3' className={style.img} />
              <div className={`${style.btn}`} onClick={handleClickCase}>立即定制</div>
            </div>
          </Fade>
          <Fade bottom>
            <div className={`flex_column_center ${style.box}`}>
            <img src={case04} alt='案例图4' className={style.img} />
              <div className={`${style.btn}`} onClick={handleClickCase}>立即定制</div>
            </div>
          </Fade>
        </div>
        
        
      </div>
      <Customize visible={visible} handleCancel={handleCancelCustomize} />
    </>
  )
}

export default Case