import style from './style.module.css'

import noData from '@/assets/images/public/noData.png'

function NotData() {
  return (
    <div className={`flex_column_center_center min_width ${style.content}`}>
      <img src={noData} alt='暂无此页面' className={`img`} />
      <span className={`con`}>404 Not Found</span>
    </div>
  )
}

export default NotData