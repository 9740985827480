/*
 * @Description: 处理路由渲染
 * @Date: 2021-03-08 17:43:14
 * @Author: 黄治恩
 */

import React, { FC } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import type { RouteOption } from './routes';

const renderRoutes: FC<RouteOption[]> = (routes) => {

  // const list = [] as RouteOption[]

  // const formatRouter = (data:RouteOption[]) => {
  //   data.forEach( route => {
  //     if(route.children?.length){
  //       formatRouter(route.children)
  //     }
  //     list.push(route)
  //   });
  // }
  // formatRouter(routes)
  
  return (
    <Switch>
      {
        routes.map( route => {
          
          const { path, redirect, exact, component: Component, children, meta } = route;
          
          // 如果是重定向
          if(redirect) {
            return <Redirect key={path} exact={exact} path={path} to={redirect}/>;
          }

          // 正常跳转
          return Component
          ? <Route
              path={path}
              exact={exact}
              key={path}
              render={ prop => {
                window.document.title = meta?.title ?? '安百纳官网'
                // 如果是重定向
                return <Component {...prop} routes={children} />
              }}
            />
          : null
        })
      }
      <Redirect from="*" to="/404" ></Redirect>
    </Switch>
  );
};
export default renderRoutes;